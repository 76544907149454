<template>
   <div>
        <div v-if="dotsLoading" class="mt-2">
            <div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
                <dots-loader/>
            </div>
        </div>
        <div v-else class="tableCornerRadius bg-white">
            <div class="table-container">
                <table>
                <thead>
                    <th scope="col" v-for="column in columnNames" :class="getColumnHeadWidth(column)" :key="column" class="bg-white cursor-pointer capitalize" @click="sort(column)"> 
                       <div v-if="column=='ownerName'" class="flex">
                            <div>{{ 'Owner' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='entityCount'" class="flex">
                            <div class="w-24">{{ 'No. of Entities' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='companyDisplayName' || column=='entityDisplayName'" class="flex">
                            <div >{{ 'Display Name' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='Action' || column=='deleteIcon'" class="flex">
                            <div >{{ '' }}</div>
                        </div>
                        <div v-else-if="column=='regionHeadQuaters'" class="flex">
                            <div >{{ 'Head Quarters' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='companyName'" class="flex">
                            <div>{{ 'Name' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column == 'entityName'" class="flex">
                            <div>{{ 'Entity' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='entityType'" class="flex">
                            <div>{{ 'Entity Type' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>
                        <div v-else-if="column=='emailAddress'" class="flex">
                            <div>{{ 'Email' }}</div>
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
                        </div>                   
                                        
                        <div v-else class="flex" :class="column == 'status' || column == 'Contract Start Date' || column == 'Contract Expiry Date' ? 'justify-center' : ''">
                            <div :class="column == 'confValue' || column == 'intValue' || column == 'avaiValue' || column == 'scValue' ||column == 'likeValue' || column == 'opsValue' || column == 'prioritypreview' ||column == 'infoClassValue' || column == 'proCValue' || column == 'orgValue' || column == 'businessValue' ||column == 'techValue' || column == 'finValue' || column == 'legalValue'||column=='severitypreview' ? 'ml-6' : ''">{{ getColumnLabel(column) }}</div>

                            <!-- <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div> -->
                            <div class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'" v-if="getColumnLabel(column) != ''"></div>
                        </div>
                    </th>
                    <tr v-if="visibility">
                       <td v-for="(name,index) in columnNames" class="bg-white" :class="name == 'status' ? 'flex items-center justify-center' : ''" :key="index">
                           <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" class="widthForSelect" v-model="filters[name]" v-if="name=='entityType'" >
                                <option :value="filters.name" selected hidden disabled>Select</option>
                                <option class="optionColors" v-for="pair in entityTypeObject" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                             <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" class="widthForSelect" v-model="filters[name]" v-if="name=='status'" >
                                <option :value="select" selected hidden disabled>Select</option>
                                <option class="optionColors" v-for="pair in activeValues" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                             <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" class="widthForSelect" v-model="filters[name]" v-if="name=='hostingType'" >
                                <option :value="filters.name" selected hidden disabled>Select</option>
                                <option class="optionColors" v-for="pair in hostingTypeObj" :key="pair" :value="pair.value">{{ pair.value }}</option>
                            </select>
                             <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" class="widthForSelect" v-model="filters[name]" v-if="name=='type'" >
                                <option :value="filters.name" selected hidden disabled>Select</option>
                                <option class="optionColors" v-for="pair in ownerTypeValues" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                            <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" class="widthForSelect" v-model="filters[name]" v-if="name=='publicFacing'">
                                <option :value="filters.name" selected hidden disabled>Select</option>
                                <option class="optionColors" v-for="pair in publicFaceValues" :key="pair" :value="pair.id">{{ pair.value }}</option>
                            </select>
                            <select :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-else-if="isColumnArrayPresentibDB(name)" class="widthForSelectLabelDef" v-model="filters[name]">
                                <option :value="filters.name" selected hidden disabled>Select</option>
                                <option class="optionColors"  v-for="option in getDbDropdownOptionsForFilter(name)" :key="option" :value="option.value">{{ option.key }}</option>
                            </select>
                            <div v-else-if="name =='Action' ||  name=='deleteIcon' || name=='entityCount' || name=='Contract Start Date'|| name=='Contract Expiry Date' || name=='Phone'"></div>
                            <div v-else-if="name =='entityType' || name == 'status' || name == 'hostingType' || name == 'type'"></div>
                            <input v-else type="text" placeholder="Search" class="searchForInputBoxStyling" v-model="filters[name]"/>
                        </td>
                    </tr>
                </thead>
                
                <tbody v-if="loadData == true">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
                                <dots-loader/>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="sortedData.length==0">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-10" :colspan="columnNames.size">
                            <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                        </td>
                    </tr>
                </tbody>
                <tbody class="empty" v-else>
                    <tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer rowHeight" >
                    
                        <td v-for="column,indexx in columnNames" :key="indexx" @mouseout="doFalse(column)" @click="onClickCallMethods(column,row,index)" class="cursor-pointer relative"> 
                            <div v-if="column=='region' && !pageType">
                                <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ getRegionName(row[column]) }}
                                </div>
                            </div>
                            <div v-else-if="column=='owner'">
                                <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ getUserName(row[column]) }}
                                </div>
                            </div> 
                             <div v-else-if="column=='type'">
                                <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ getTypeName(row[column]) }}
                                </div>
                            </div> 
                             <div v-else-if="column=='hostingType'">
                                <div v-if="row[column] !== '-' && row[column] !== ''" class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ getHostingType(row[column]) }}
                                </div>
                                <div v-else>N/A</div>
                            </div>     
                            <div v-else-if="column=='entityType'">
                                <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ getEntityType(row[column]) }}
                                </div>
                            </div>
                            <div v-else-if="column=='Contract Start Date' || column=='Contract Expiry Date'" class="text-center">
                                <!-- <div class="table-ellipsis" @mousemove="toolTip(column, $event, index, row, 'text')"> -->
                                    {{ changeDateFormat(row[column]) }}
                                <!-- </div> -->
                            </div> 
                            <div v-else-if="column == 'publicFacing'">
                                {{getPublicFacingValueFromMethod(row[column])}}
                                
                            </div>
                            <!-- <div v-else-if="column == 'vendor'">
                                {{row[column].label}}
                            </div> -->
                            <div v-else-if="column == 'status'">
                                <div class="rounded-full w-5 h-5 bg-green mx-auto" @mousemove="toolTip(column, $event, index, row, 'circleButton')" v-if="row[column] == true"></div>
									<div class="rounded-full w-5 h-5 bg-error mx-auto" @mousemove="toolTip(column, $event, index, row, 'circleButton')" v-else></div>
									<div class="flex justify-center items-center bg-white opacity-0 lasttooltip absolute z-50 group-hover:opacity-100 border pointer-events-none" v-if="row[column] == true && pop == true">
										<img src="@/assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
										<span class="text-black text-center text-xs"></span>
										Active
									</div>
									<div class="flex justify-center items-center bg-white opacity-0 lasttooltip absolute z-50 group-hover:opacity-100 border pointer-events-none cursor-pointer" v-if="row[column] == false && pop == true">
										<img src="@/assets/tooltip.svg" alt="" class="absolute text-black bottom-full left-0 -mb-1 h-3.5 w-3" />
										<span class="text-black text-center text-xs"></span>
										Inactive
									</div>
                            </div>   
                            <div v-else-if="column == 'Action'" class="flex justify-center">
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" />
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" :class="isNearBottom(index) ? 'dropdown-up' : ''"  v-if="showDropDown && selectedRowIndex == index" >
                                    <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in optionsValueAtActionButton" @click="optionClickedOnAction(option,row)" :key="index" >
                                        
                                        <div class="flex items-center">
                                            <img src="@/assets/Entity.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div v-else-if="column == 'deleteIcon'">
								<div class="flex">
									<img src="@/assets/deleteIcon.svg" class="h-5 cursor-pointer w-5 mx-2.5"/>
								</div>
							</div>
                            <div v-else-if="column=='entitiesCount'">
                                <div @mousemove="toolTip(column, $event, index, row, 'text')">
                                    {{ row[column] }}
                                </div>
                            </div> 
                            <div v-else-if="isColumnArrayPresent(column)">
                                <div v-if="column == 'priority' || column == 'prioritypreview'">
                                    <div class="flex items-center justify-center rounded h-5 w-28 px-2.5  regular" :class="colorPriorityBg(row[column])" v-if="row[column]">
                                        <div :class="colorPriorityText(row[column])">
                                            {{
                                                dbPriority.find((l) => {
                                                    return l.value == row[column];
                                                }).key
                                            }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                            </div>
                            <div v-else-if="getLabelsArrayForColumnInStore(column)">
                                <div v-if="column == 'severitypreview'">
                                    <div class="relative regular ml-8">
                                        <div class="flex items-center justify-center rounded-full w-5 h-5" :class="colorPropBg('border', row.severitypreview)">
                                            <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severitypreview)"></div>
                                        </div>
                                    </div> 
                               </div>
                                <div v-else class="flex items-center justify-center rounded h-5 w-28 px-2.5 regular" :class="colorRiskBg(row[column])">
                                    <div class="table-ellipsisForValue" style="white-space: break-spaces;" :class="colorRiskText(row[column])" @mousemove="toolTip(column, $event, index, row, 'label')">
                                        {{getLabelDefinationName( row[column])}}
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="column == 'severity'">
                                <div class="relative flex items-center justify-center regular">
                                    <div class="flex items-center justify-center rounded-full w-5 h-5" @mousemove="toolTip(column, $event, index, row, 'circleButton')" :class="colorPropBg('border', row.severity)">
                                        <div class="severityCircle rounded-full" :class="colorPropBg('circle', row.severity)"></div>
                                    </div>
                                </div>
                            </div>
                                <div v-else class="flex items-center justify-center relative rounded w-28 px-2.5 h-5 regular" :class="colorTextRequestStatus(row[column])">
                                    <div class="w-full h-full absolute opacity-10 rounded" :class="colorBgRequestStatus(row[column])"></div>
                                    <div class="flex rounded w-32 items-center justify-center py-1 regular text-xs">
                                        <div class="text-xs">
                                            {{ getColumnRealtedValueFromArray(row[column], column) }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="getLabelsArrayForColumnInStore(column)">
                                <div class="flex items-center justify-center rounded h-5 w-24" @mousemove="toolTip(column, $event, index, row, 'text')" :class="colorRiskBg(row[column])">
                                    <div :class="colorRiskText(row[column])">
                                        {{getLabelFromColumnArrayStore(row[column])}}
                                    </div>
                                </div>
                            </div>      
                            <div v-else>
                                <div class="table-ellipsis" >
                                    <p class="fullText" :class="applyStylesForColumn(column)" @mousemove="toolTip(column, $event, index, row, 'text')">
                                        {{ row[column] }}
                                    </p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
               </table>
            </div>
            <pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
        </div>
  </div>  
</template>
<style scoped>
.lastChild:last-child {
    border: 0px;
}
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
.table-ellipsisForValue {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.empty tr td {
  border-bottom: 1px solid #e9e9e9 !important;
}
.widthForActionButton{
    width : 60px;
}
.zIndexingForActionDropDown{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: absolute;
}
.actionButtonStyling {
    left: -17px;
    bottom: -39px;
}
.descriptionAtLabelDef{
    width:410px !important
}
.widthForSelect{
    width : 100px !important;
}
.widthForSelectLabelDef{
    width : 150px !important
}
/* .widthForjobTitle{
    width : 175px !important;
} */
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
</style>
<script lang="ts">
import { emitter } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
    props: ['tableRowData','headersData','openPopup','navigate','loadData'],
    data(): any {
        return {
            tableHead: [],
            tableRow: [],
            searchTableRow: [],
            visibility: false,
            className: '',
            classSort: '',
            currentSort: 'name',
            currentSortDir: 'asc',

            currentPage: '',
            pageSize: '',
            dynamicPagination:'',

            pop: false,
            styling: { left: '', bottom: '' , top : '' , right : ''},
            tipText: '',
            tipColumn: '',
            tipIndex: '',
            filters: {},
            selectedRowIndex:'',
            pageType:false,
            regionList:[],
            businessEntities:[],
            users:[],
            optionsUserArray:[],
            checklabelsforallevents: [],
            columns: [
                { key: 'controlName', value: 'Name' },
                { key: 'informationCategory', value: 'Category' },
                { key: 'processName', value: 'Process Name' },
                { key: 'organizationName', value: 'Organization Name' },
                { key: 'tier', value: 'Tier' },
            ],
            activeValues: [
				{
					id: 1,
					value: 'Active'
				},
				{
					id: 2,
					value: 'Inactive'
				}
			],
             hostingTypeObj:[
                {
                    id: 1,
                    value: 'On-premise'
                },
                {
                    id: 2,
                    value: 'Cloud'
                },
            ],
            publicFaceValues:[
                {
					id: 1,
					value: 'Yes'
				},
				{
					id: 2,
					value: 'No'
				}
            ],
            entityTypeObject:[
                {
                    id:1,
                    value:'Primary'
                },
                {
                    id:2,
                    value:'Subsidary'
                },
                {
                    id:3,
                    value:'Secondary'
                }
            ],
            ownerTypeValues: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            optionsValueAtActionButton: [
                {
                    id: 1,
                    value: 'Edit',
                },
                {
                    id: 2,
                    value: 'Teams',
                },
            ],
            showDropDown: false,
        };
    },
    components: {
        pagination,
        dotsLoader
    },
    async mounted() {
       this.pageType=this.$route.fullPath.includes('regions')
        await this.getAllUsers();
        await this.getAllRegions();
        await this.getAllEntities();

        this.initialize();
        emitter.on('visible', (isBoolean: any) => {
            this.visibility = isBoolean;
			this.visibility == false ? (this.filters = {}) : '';
            
        });
        emitter.on('*', (type, booleanValue) => {
        	if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
        		this.showDropDown == true ? (this.showDropDown = false) : '';
        	} else {
        		if (this.showBlock === true && type != 'deActiviteToolTip' ) {
        			this.showDropDown == true ? (this.showDropDown = booleanValue) : '';
        		} else if (this.showBlock === false) {
        			this.showDropDown == true ? '' : (this.showDropDown = booleanValue);
        			this.showBlock = true;
        		}
        	}
        });

        // if(this.$route.name === 'teamsForEntity' && Object.keys(this.$route.params).includes('entityName') ){
        //     this.visibility = true
        //     this.filters.entityName = this.$route.params.entityName
        // }
    },
    computed: {
        ...mapGetters({
            userInfo: 'userInfo',
            dbSeverity: 'dbSeverity',

            dbLikelihood: 'dbLikelihood',
            dbBussiness: 'dbBussiness',
            dbOrganization: 'dbOrganization',
            dbOperations: 'dbOperations',
            dbLegal: 'dbLegal',
            dbFinancial: 'dbFinancial',
            dbTechnical: 'dbTechnical',
            dbProcessCriticality: 'dbProcessCriticality',
            dbAvailability: 'dbAvailability',
            dbConfidentiality: 'dbConfidentiality',
            dbIntegrity: 'dbIntegrity',
            dbInformationClassification: 'dbInformationClassification',
            dbSecurityCategory: 'dbSecurityCategory',
            dbPriority: 'dbPriority',
            dbOverAllImpact:'dbOverAllImpact',
            dotsLoading: 'dotsLoading' 
        }),
        sortedData: function (): any {
    return [...this.tableRow]
        .filter((row: any) => {
            for (const key in this.filters) {
                const inputValue = this.filters[key];
                 if (row[key] == '') {
                        return false;
                    }
                if (inputValue !== '' && row[key]) {
                    if (Number.isInteger(row[key])) {
                        if (String(row[key]).toLowerCase().includes(String(inputValue).toLowerCase()) === false) {
                            return false;
                        }
                    } else {
                        if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
                            return false;
                        }
                    }
                }
            }
            return true;
        })
        .sort((a, b) => {
            let modifier = 1;
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
            if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
            return 0;
        })
        .filter((row, index) => {
            this.dynamicPagination = index + 1;
            let start = (this.currentPage - 1) * this.pageSize;
            let end = this.currentPage * this.pageSize;
            if (index >= start && index < end) return true;
        });
},
        columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id') names.add(key);
            }
            return names;
        }
    },
    watch: {
       visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
			},
			deep: true
		}
    },
    methods: {
        initialize() {
            this.tableRow = this.tableRowData;
            this.searchTableRow = this.tableRowData;
        },
        getPublicFacingValueFromMethod(datavalue:any){
            if(datavalue == undefined){
                return 'N/A'
            }else{
                let a = (this.publicFaceValues.find((obj:any)=>{return obj.id == datavalue}));
                if(a == undefined){
                    return 'N/A'
                }else{
                    return a.value
                }
            }
        },
        changeDateFormat(date: any) {
			// if (date.includes('-')) {
			// 	let dateParts = date.split('-');
			// 	if (dateParts.length === 6) {
			// 	let startDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
			// 	let endDate = `${dateParts[5]}/${dateParts[4]}/${dateParts[3]}`;
			// 	return `${startDate} - ${endDate}`;
			// 	} else if (dateParts.length === 3) {
			// 	return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
			// 	}
			// }
            if(date != undefined){
                return date.split('-').reverse().join('-');
            }
		},
        isNearBottom(index:any) {
			// console.log("((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))",((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))),
			// console.log("index >= this.sortedData.length - 2",index >= this.sortedData.length - 2)
			if(((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))){
				return index >= this.sortedData.length - 2;
			}else{
				return index >= this.sortedData.length - 2;
			}
			
		},
        paginationUpdations(currentPage:any,pageSize:any){
            this.currentPage=currentPage;
            this.pageSize=pageSize;
        },
        sort(tableHead: any) {
            this.className = tableHead;
            if (tableHead === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            }
            this.currentSort = tableHead;
            this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
             let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
           if(ValueKeys.includes(column)){
               this.tipText = this.getLabelDefinationName( row[column])
            }else{
               this.tipText = row[column];
            }
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e,column);
					break;
			 case 'label':
                    let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
                      if(ValueKeys.includes(column)){
                         const container = e.currentTarget.closest('.table-ellipsisForValue');
                          if (container && this.isTextOverflowed(container)) {
                             let obj:any = {
                                tipText : this.tipText
                            }

                            emitter.emit('activiteToolTip',obj)
                          }
                     }
                     break;
                     case 'circleButton':
                        if(this.tipText == 1){
                           let obj:any = {
                                tipText : 'Active'
                                
                            }
                             emitter.emit('activiteToolTip',obj)
                        }else if(this.tipText == 2){
                           let obj:any = {
                                tipText : 'In Active'
                            }
                             emitter.emit('activiteToolTip',obj)
                        }
                     break;
            }
		},
        handleTextTooltip(e:any, column :any) {
            const container = e.currentTarget.closest('.table-ellipsis');
            let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
           if (container && this.isTextOverflowed(container)) {
              let obj:any = {
                tipText : this.tipText
              }

              emitter.emit('activiteToolTip',obj)
                
            } 
        },
        isTextOverflowed(element:any) {
            return element.scrollHeight > element.clientHeight;
        },
        onClickCallMethods(column:any,row:any,index:any){
            if(column == 'Action'){
                this.clickedOnActionButton(row, index, column)
            }else if(column == 'deleteIcon'){
                 this.emitDeleteRowEvent(row,index)
            }else{
                this.actionOnClickingTableRow(row)
                this.showBlock = false;
            }
        //    column=='Action'? clickedOnActionButton(row, index, column) :  actionOnClickingTableRow(row) 
        },
        actionOnClickingTableRow(obj:any){
            emitter.emit('dataTransfering', obj);
        },
        actionClick(obj:any){
            emitter.emit('goToInnerPage', obj);
        },
        doFalse(column:any) {
           
             emitter.emit('deActiviteToolTip')
        },
        applyStylesForColumn(column : any){
          if(column == 'entityCount' ){
             return 'ml-11'
          }else if(column == 'Contract Start Date' || column == 'Contract Expiry Date'){
            return 'flex justify-center'
          }
        },
        getRegionName(regionId:any){
            let regionDetails = this.regionList.find((obj:any)=>{return obj.value == regionId});
            if(regionDetails != undefined){
                return regionDetails.label;
            }else{
                return '';
            }            
        },
        getEntityName(entityId:any){
          let entityDetails = this.businessEntities.find((obj:any)=>{return obj.value == entityId.value});
            if(entityDetails != undefined){
                return entityDetails.label;
            }else{
                return '';
            }            
        },
        getEntityNameForHeadQuaters(newId:any){  
             let entityDetails = this.businessEntities.find((obj:any)=>{
               return obj == newId
            });
            if(entityDetails != undefined){
                return entityDetails.label;
            }else{
                return '';
            }         
        },
        getUserName(userId:any){
            let userDetails = this.optionsUserArray.find((obj:any)=>{return obj.value == userId});   
            if(userDetails != undefined){
                return userDetails.label
            }else{
                return '';
            }
        },
        getTypeName(typeId:any){
             let typeName = this.ownerTypeValues.find((obj:any)=>{return obj.id == typeId});   
            if(typeName != undefined){
                return typeName.value
            }else{
                return '';
            }
        },
        getHostingType(hostType:any){
             let hostName = this.hostingTypeObj.find((obj:any)=>{return obj.value == hostType});   
            if(hostName != undefined){
                return hostName.value
            }else{
                return 'N/A';
            }
        },
        getUserLabel(userId:any){
           let userDetails = this.optionsUserArray.find((obj:any)=>{return obj.value == userId.value});   
            if(userDetails != undefined){
                return userDetails.label
            }else{
                return '';
            }
        },
        getEntityType(comingId:any){
            let findObj = this.entityTypeObject.find((obj:any)=>{return obj.id == comingId});
            if(findObj != undefined){
                return findObj.value
            }else{
                return '';
            }
        },
        async getAllRegions(){
            this.regionList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
                res.data.map((obj:any)=>{
                    let a = { value: obj._id , label: obj.region }
                    this.regionList.push(a);
                })
            })
        },
        async getAllUsers() {
            this.optionsUserArray = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
                this.users = res.data;
                for (var i = 0; i < this.users.length; i++) {
                   this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
                }
            });          
        },
        async getAllEntities(){
			this.businessEntities = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				res.data.map((obj:any)=>{
					let a = {value:obj._id, label:obj.entityName};
					this.businessEntities.push(a);
				})
                             
            })
		},
        optionClickedOnAction(actionOption:any,rowData:any){
            if(actionOption.id == 1){
                emitter.emit('editEntity', rowData);
            }else if(actionOption.id == 2){
                emitter.emit('showTeamsForEntity', rowData);
            }
            
		},
        emitDeleteRowEvent(rowData : any){
            emitter.emit('deleteRowFromTable',rowData)
        },
        clickedOnActionButton(rowData: any, index: any, column: any) {
            if (column == 'Action') {
                if (this.selectedRowIndex === index) {
                    this.showDropDown = !this.showDropDown;
                } else {
                    this.showDropDown = true;
                    this.selectedRowIndex = index;
					// this.actionButtonRow = rowData
                }
            }else{
				emitter.emit('clickedOnDropDownAction',rowData)
			}
			this.showBlock = false;
		},
        getColumnRealtedValueFromArray(columnName: any, type: any) {
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            const option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
            return option ? option1.value : [];
        },
        getColumnHeadWidth(column: any) {
            if(column == 'description'){
                return 'descriptionAtLabelDef'
            }else if(column == 'Action' ||  column == 'deleteIcon'){
                return 'widthForActionButton'
            }
            // else if(column == 'status'){
            //     return 'w-20'
            // }
            let ValueKeys:any = ['teamName','entity','teamOwner']
            if(ValueKeys.includes(column)){
               return 'w-48';
            }
            let values:any = ['confLabel','Phone','intLabel','avaiLabel','scLabel','likeLabel','opsLabel','orgLabel','businessLabel','techLabel','finLabel','legalLabel','sevLabel','prioLabel','infoClassLabel','proCLabel','overAllLabel','Name','vendor']
            if(values.includes(column)){
               return 'w-32';
            }
            let keys:any = ['team','status','publicFacing','hostingType','type']
            if(keys.includes(column)){
               return 'w-36';
            }
            let values1:any = ['userName']
            if(values1.includes(column)){
               return 'w-44';
            }
            // let keys1:any = ['']
            // if(keys1.includes(column)){
            //    return 'w-28';
            // }
            let keys2:any = ['region','entityCount','regionHeadQuaters','Email','department','Process Name','Owner']
            if(keys2.includes(column)){
               return 'w-56';
            } 
            let keys3:any = ['systemName','entityName','entityType','entityDisplayName','regionName','ownerName', 'Display Name', 'Responsibility', 'Contract Start Date', 'Contract Expiry Date']
            if(keys3.includes(column)){
               return 'w-40';
            }else if(column == 'Job Title'){
                return 'w-60'
            }
            let keys4:any = ['email','Description','impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
             if(keys4.includes(column)){
               return 'w-52';
            }else if(column == 'jobTitle'){
                return 'w-36'
            } 
            
        },
        isColumnArrayPresent(columnName: any) {
            // return this.dropdownOptions.some((option: { key: any }) => option.key === columnName);
            let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
            if(ValueKeys.includes(columnName)){
                return true
            }else{
                return false
            }
        },
        getDbDropdownOptionsForFilter(column:any){
            if(column=='priority' || column=='prioritypreview'){
                return this.dbPriority
            }else if(column=='severity' || column=='severitypreview'){
                return this.dbSeverity
            }else if(column=='impact' || column=='overAllValue'){
                return this.dbOverAllImpact
            }else if(column=='confValue'){
                return this.dbConfidentiality
            }else if(column=='intValue'){
                return this.dbIntegrity
            }else if(column=='avaiValue'){
                return this.dbAvailability
            }else if(column=='scValue' || column=='securityCategory'){
                return this.dbSecurityCategory
            }else if(column=='likeValue' || column=='likelihood'){
                return this.dbLikelihood
            }else if(column=='opsValue'){
                return this.dbOperations
            }else if(column=='orgValue'){
                return this.dbOrganization
            }else if(column=='businessValue'){
                return this.dbBussiness
            }else if(column=='techValue'){
                return this.dbTechnical
            }else if(column=='finValue'){
                return this.dbFinancial
            }else if(column=='legalValue'){
                return this.dbLegal
            }else if(column=='infoClassValue' || column=='infopreview'){
                return this.dbInformationClassification
            }else if(column=='proCValue'){
                return this.dbProcessCriticality
            }
        },
        getLabelFromColumnArrayStore(cameId:any){
            let labelObj = this.checklabelsforallevents.find((l:any) => {return l.value == cameId;})
            if(labelObj == undefined){
                return '-'
            }else{
                return labelObj.key;
            }  
        },
        getLabelDefinationName(value:any){
            let obj =  this.checklabelsforallevents.find((l:any) => { return l.value == value; });
            if(obj!=undefined){
                return obj.key
            }else{
                return '-'
            }
        },
        getLabelsArrayForColumnInStore(column: any) {
            if (column == 'securityCategory' || column=='scValue') {
                this.checklabelsforallevents = this.dbSecurityCategory;
                return true;
            }else if(column=='impact'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true;
            }else if(column=='severitypreview'){
                this.checklabelsforallevents = this.dbSeverity;
                return true;
            }else if(column=='likelihood' || column=='likeValue'){
                this.checklabelsforallevents = this.dbLikelihood;
                return true;
            }else if(column=='confValue'){
                this.checklabelsforallevents = this.dbConfidentiality;
                return true;
            }else if(column=='intValue'){
                this.checklabelsforallevents = this.dbIntegrity;
                return true;
            }else if(column=='overAllValue'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true;
            }else if(column=='avaiValue'){
                this.checklabelsforallevents = this.dbAvailability;
                return true;
            }else if(column=='scValue'){
                this.checklabelsforallevents = this.dbSecurityCategory;
                return true;
            }else if(column=='infoClassValue' || column=='infopreview'){
                this.checklabelsforallevents = this.dbInformationClassification;
                return true;
            }else if(column=='priority' || column=='prioritypreview'){
                this.checklabelsforallevents = this.dbPriority;
                return true;
            }else if(column=='proCValue'){
                this.checklabelsforallevents = this.dbProcessCriticality;
                return true;
            }else if(column=='opsValue'){
                this.checklabelsforallevents = this.dbOperations;
                return true;
            }else if(column=='orgValue'){
                this.checklabelsforallevents = this.dbOrganization;
                return true;
            }else if(column=='businessValue'){
                this.checklabelsforallevents = this.dbBussiness;
                return true;
            }else if(column=='finValue'){
                this.checklabelsforallevents = this.dbFinancial;
                return true;
            }else if(column=='legalValue'){
                this.checklabelsforallevents = this.dbLegal;
                return true;
            }else if(column=='techValue'){
                this.checklabelsforallevents = this.dbTechnical;
                return true;
            }else if(column == 'confidentiality'){
                this.checklabelsforallevents = this.dbConfidentiality;
                return true
            }else if(column == 'integrity'){
                this.checklabelsforallevents = this.dbIntegrity;
                return true
            } else if(column == 'availability'){
                this.checklabelsforallevents = this.dbAvailability;
                return true
            } else if(column == 'overAllValue'){
                this.checklabelsforallevents = this.dbOverAllImpact;
                return true
            }else{
                return false;
            }
        },
        getColumnLabel(columnName: any) {
            let labelKeys:any = ['confLabel','intLabel','avaiLabel','scLabel','likeLabel','opsLabel','orgLabel','businessLabel','techLabel','finLabel','legalLabel','sevLabel','prioLabel','infoClassLabel','proCLabel']
            let ValueKeys:any = ['confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue']

            if (columnName == 'actionButton' || columnName == 'deleteinfo' || columnName == 'infoAction' || columnName == 'deleteIcon' || columnName == 'reportDelete' || columnName == 'reportAction' || columnName == 'tableDataActions' || columnName == 'History') {
                return '';
            }else if(labelKeys.includes(columnName)){
                return 'Label';
            }else if(ValueKeys.includes(columnName)){
                return 'Value';
            }
            
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
        isColumnArrayPresentibDB(column:any){
            let ValueKeys:any = ['impact','likelihood','overAllValue','priority','infopreview','severity','confValue','intValue','avaiValue','scValue','likeValue','opsValue','orgValue','businessValue','techValue','finValue','legalValue','severitypreview','prioritypreview','infoClassValue','proCValue','securityCategory']
            if(ValueKeys.includes(column)){
                return true
            }else{
                return false
            }
        },
        colorPropBg(format: any, scaleScore: any) {
            if (format == 'circle') {
                if (scaleScore == 5) {
                    return ['bg-darkred'];
                } else if (scaleScore == 4) {
                    return ['bg-error'];
                } else if (scaleScore == 3) {
                    return ['bg-yellow'];
                } else if (scaleScore == 2) {
                    return ['bg-green'];
                } else if (scaleScore == 1) {
                    return ['bg-lightgreen'];
                }
            } else if (format == 'border') {
                if (scaleScore == 5) {
                    return ['border-darkred border-4'];
                } else if (scaleScore == 4) {
                    return ['border-error border-4'];
                } else if (scaleScore == 3) {
                    return ['border-yellow border-4'];
                } else if (scaleScore == 2) {
                    return ['border-green border-4'];
                } else if (scaleScore == 1) {
                    return ['border-lightgreen border-4'];
                }
            }
        },
        colorRiskBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        colorRiskText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
        colorPriorityBg(value: any) {
            if (value == 1) {
                return ['bg-veryLowColor border-solid border border-lightgreen'];
            } else if (value == 2) {
                return ['bg-lowColor border-solid border border-green'];
            } else if (value == 3) {
                return ['bg-moderateColor border-solid border border-yellow'];
            } else if (value == 4) {
                return ['bg-highColor border-solid border border-error'];
            } else if (value == 5) {
                return ['bg-veryHighColor border-solid border border-darkred'];
            }
        },
        colorPriorityText(value: any) {
            if (value == 1) {
                return ['text-lightgreen font-semibold'];
            } else if (value == 2) {
                return ['text-green font-semibold'];
            } else if (value == 3) {
                return ['text-yellow font-semibold'];
            } else if (value == 4) {
                return ['text-error font-semibold'];
            } else if (value == 5) {
                return ['text-darkred font-semibold'];
            }
        },
    },
});
</script>

