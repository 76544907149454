<template>
    <div class="pt-3 pl-5 pr-5 setBox overflow-y-hidden">
       
        <div class="flex justify-between items-center">
            <h1 class="title">Vendors</h1>
            <div class="flex items-center">
                <div class="noteam text-center">
                    <button class="btnprimary mr-2" @click="showPopupByADD(user,'add')">Add</button>
                </div>
                <input type="file" class="hidden" accept=".xls,.xlsx" ref="file" @change="fileReader($event)" />
                <div>
                    <div v-if="selectedIndex==20003">
                        <button  @click="filterOpen" class="flex" :class="tableRow1.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div v-if="selectedIndex==20004">
                        <button  @click="filterOpen" class="flex" :class="tableRow2.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="mt-3 fontSizeInPage">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
            velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>

        <div class="mt-5 px-5 pb-5 bg-white w-full border-lightgrey pt-5 relative rounded">
            <div class="group-buttons" v-if="isSmallScreen">
                <!-- Group navigation buttons for smaller screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': (selectedIndex === 0 || currentGroupIndex === 0) && isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': (selectedIndex === carouselHead.length - 1 || currentGroupIndex >= totalGroups - 1) && isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            <div v-else>
                <!-- Scroll buttons for larger screens -->
                <div class="absolute left-0 cursor-pointer" @click="scrollTabs('left')" :class="{ 'disabled pointer-events-none': !canScrollLeft && !isSmallScreen }">
                    <img src="@/assets/leftArrowFilled.svg" class="arrow left-0" />
                </div>
                <div class="absolute right-0 cursor-pointer" @click="scrollTabs('right')" :class="{ 'disabled pointer-events-none': !canScrollRight && !isSmallScreen }">
                    <img src="@/assets/rightArrowFilled.svg" class="arrow right-0" />
                </div>
            </div>
            <div class="tab-container flex items-center w-full ">
                <!-- {{ visibleTabs }}{{ selectedIndex }} -->
                <div class="tab-container flex items-center w-full gap-12" ref="tabContainer" @scroll="handleScroll">
                    <div class="pb-1 text-sm" :class="selectedIndex == tab.id ? 'adjust font-semibold primary':'text-sm text-disabledgrey'" v-for="(tab, index) in visibleTabs" :key="index" @click="toggleTabs(tab.id)">
                        <div>
                            <div ref="actualIndex" class="cursor-pointer tab-name p-0.75">{{ tab.displayName }}</div>
                            <div :class="{ progress: selectedIndex === tab.id }" class="h-1 p-0.75 relative rounded"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="progressbarB h-1 rounded"></div>
           
            <div v-if="selectedIndex==20003">
                <newTable :tableRowData="tableRow1" :headersData="headers1" :openPopup="true" :loadData="dataLoading"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>
            <div v-if="selectedIndex==20004">
                <newTable :tableRowData="tableRow2" :headersData="headers2" :openPopup="true" :loadData="dataLoading"></newTable>
                <div v-if="toolTipActive">
                    <tool-tip :tipText="toolTipText"></tool-tip>
                </div>
            </div>
        </div>

        <popup v-if="selectedIndex == 20003 && popUp" :popUp="popUp" >
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Vendors</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Name</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdParty.name"
                                            placeholder="Name"
                                            :class="{
                                                'is-invalid': v$.thirdParty.name.$error || doVendorExist
                                            }"
                                            @input = "v$.thirdParty.name.$touch()"
                                        />
                                        <div v-if="v$.thirdParty.name.$error || doVendorExist" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.name.required.$invalid">Required</div>
                                            <div class="error-text" v-else-if="doVendorExist">Vendor Already Exist</div>
											<div v-else-if="v$.thirdParty.name.required && v$.thirdParty.name.restrictCharacters20.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Display Name</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdParty.displayName"
                                            placeholder="Display Name"
                                            :class="{
                                                'is-invalid': v$.thirdParty.displayName.$error || doDisplayNameExist
                                            }"
                                            @input = "v$.thirdParty.displayName.$touch()"
                                        />
                                        <div v-if="v$.thirdParty.displayName.$error || doDisplayNameExist" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.displayName.required.$invalid">Required</div>
                                            <div class="error-text" v-else-if="doDisplayNameExist">DisplayName Already Exist</div>
											<div v-else-if="v$.thirdParty.displayName.required && v$.thirdParty.displayName.restrictCharacters20.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Industry Sector</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdParty.industrySectorName"
                                            placeholder="Industry Sector"
                                            :class="{
                                                'is-invalid': v$.thirdParty.industrySectorName.$error
                                            }"
                                            @input = "v$.thirdParty.industrySectorName.$touch()"
                                        />
                                        <div v-if="v$.thirdParty.industrySectorName.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.industrySectorName.required.$invalid">Required</div>
											<div v-else-if="v$.thirdParty.industrySectorName.required && v$.thirdParty.industrySectorName.restrictCharacters20.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Email</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim ="thirdParty.emailAddress"
                                            placeholder="Email"
                                            @input="v$.thirdParty.emailAddress.$touch()"
                                            :class="{
                                                'is-invalid': v$.thirdParty.emailAddress.$error || checkMail
                                            }"
                                        />
                                        <div v-if="v$.thirdParty.emailAddress.$error|| checkMail" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.emailAddress.required.$invalid">Email is required</div>
                                            <div v-else-if="v$.thirdParty.emailAddress.required && v$.thirdParty.emailAddress.email.$invalid" class="invalid-feedback text-xs">Email is Invalid</div>
											<p v-else-if="checkMail" class="error-text">email shouldn't be of same domain</p>
											<div v-else-if="v$.thirdParty.emailAddres.required && v$.thirdParty.emailAddres.restrictCharacters60.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Phone</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdParty.phoneNumber"
                                            placeholder="Phone"
                                            :class="{
                                                'is-invalid': v$.thirdParty.phoneNumber.$error
                                            }"
                                            @input="v$.thirdParty.phoneNumber.$touch()" 
                                        />
                                        <div v-if="v$.thirdParty.phoneNumber.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.phoneNumber.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.phoneNumber.required && v$.thirdParty.phoneNumber.numeric.$invalid" class="invalid-feedback text-xs">Phone Number is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <label class="controllabel">Responsibility</label>
                                    <!-- <select class="inputboxstyling" :class="{ 'is-invalid': v$.thirdParty.responsibleBusinessEntity.$error }" v-model="thirdParty.responsibleBusinessEntity">
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option v-for="item in entitiesList" :key="item" :value="item.value">{{ item.label }}</option>
                                    </select> -->
                                    <multiselect
                                        v-model="thirdParty.responsibleBusinessEntity"
                                        :options="entitiesListing"
                                        @search-change="asyncFindLocation"
                                        :showNoOptions="false"
                                        open-direction="bottom"
                                        :multiple="false"
                                        :close-on-select="true"
                                        :hide-selected="true"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"
                                        :searchable="true"
                                        :hideArrow="false"
                                        placeholder="Responsibility"
                                        class="custom-multiselect"
                                        :class="{
                                            'is-invalid borderRed': v$.thirdParty.responsibleBusinessEntity.$error,
                                            'has-options': entitiesListing && entitiesListing.length > 0,
                                            'no-options': !entitiesListing.length &&  searchValue !== '',
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Entities Found</span>
                                        </template>
                                    </multiselect>

                                    <div v-if="v$.thirdParty.responsibleBusinessEntity.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.thirdParty.responsibleBusinessEntity.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="w-full upperspacing">
                                    <label class="controllabel">Entities that use the Vendors</label>
                                    <multiselect
                                        v-model="thirdParty.entitiesusethirdparty"
                                        :options="entitiesListing"
                                        @search-change="asyncFindLocation"
                                        :searchable="true"
                                        :showNoOptions="false"
                                        :multiple="true"
                                        :hide-selected="true"
                                        :close-on-select="false"
                                        :clear-on-select="true"
                                        open-direction="bottom"
                                        label="label"
                                        track-by="value"    
                                        :hideArrow="false"
                                        placeholder="Entities that use the Vendors"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed':  v$.thirdParty.entitiesusethirdparty.$error,
                                            'has-options': entitiesListing && entitiesListing.length > 0,
                                            'no-options': !entitiesListing.length &&  searchValue !== '',
                                        }"
                                        mode="tags"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Entities Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.thirdParty.entitiesusethirdparty.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.thirdParty.entitiesusethirdparty.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12">
                                        <label class="controllabel">Contract Start Date</label>
                                        <input
                                            type="date"
                                            @focus="openCalendar"
                                            onkeydown = "return false"
                                            v-model="thirdParty.contractStartDate"
                                            :class="{
                                                'is-invalid': v$.thirdParty.contractStartDate.$error,'inputboxstylingForSelectAtPopup': thirdParty.contractStartDate === '','stylingForInputboxstylingForSelectAtPopup': thirdParty.contractStartDate !== ''
                                            }"
                                        />
                                        <div v-if="v$.thirdParty.contractStartDate.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.contractStartDate.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Contract Expiry Date</label>
                                        <input
                                            type="date"
                                            @focus="openCalendar"
                                            onkeydown = "return false"
                                            v-model="thirdParty.contractExpirationDate"
                                            :class="{
                                                'is-invalid': v$.thirdParty.contractExpirationDate.$error,'inputboxstylingForSelectAtPopup': thirdParty.contractExpirationDate === '','stylingForInputboxstylingForSelectAtPopup': thirdParty.contractExpirationDate !== ''
                                            }"
                                        />
                                        <div v-if="v$.thirdParty.contractExpirationDate.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.contractExpirationDate.required.$invalid">Required</div>
                                            <div class="error-text text-xs" v-else-if="v$.thirdParty.contractExpirationDate.required && v$.thirdParty.contractExpirationDate.minValue.$invalid">End Date must be greater than start date</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Website Address</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim ="thirdParty.website"
                                            placeholder="Website Address"
                                            @input="v$.thirdParty.website.$touch()"
                                            :class="{
                                                'is-invalid': v$.thirdParty.website.$error
                                            }"
                                        />
                                        <div v-if="v$.thirdParty.website.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.website.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.website.required &&  v$.thirdParty.website.websiteValidate.$invalid" class="error-text text-xs">Invalid Website</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Country</label>
                                        <!-- <dropdown :dataprops="selectedCategory" :inputValue="thirdParty.country" :placeholderValue="'Country'" @name="getCategories" :error="v$.thirdParty.country.$error"></dropdown> -->
                                        <multiselect
                                            v-model="thirdParty.country"
                                            @select="changeCountry(thirdParty.country)"
                                            :options="countriesList"
                                            :searchable="true"
                                            :minChars="1"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :filterResults="true"
                                            :hide-selected="true"
                                            class="custom-multiselect userSelect"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            placeholder="Country"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdParty.country.$error,
                                                'has-options': countriesList && countriesList.length > 0,
                                                'no-options':  !countriesList.length,
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Country Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdParty.country.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.country.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.country.required && v$.thirdParty.country.alphabet.$invalid" class="invalid-feedback text-xs">Country is Invalid</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">State</label>
                                        <!-- <dropdown :dataprops="selectedCategory" :inputValue="thirdParty.state" :placeholderValue="'State'" @name="getCategories" :error="v$.thirdParty.state.$error"></dropdown> -->
                                        <multiselect
                                            v-model="thirdParty.state"
                                            :options="statesList"
                                            :searchable="true"
                                            :minChars="1"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :filterResults="true"
                                            :hide-selected="true"
                                            class="custom-multiselect userSelect"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            @select="changeState(thirdParty.state)"
                                            :disabled="thirdParty.country == ''"
                                            placeholder="State"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdParty.state.$error,
                                                'has-options': statesList && statesList.length > 0,
                                                'no-options' :  !statesList.length && thirdParty.state
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No State Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdParty.state.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.state.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.state.required && v$.thirdParty.state.alphabet.$invalid" class="invalid-feedback text-xs">State is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">{{ 'City' }}</label>
                                        <multiselect
                                            v-model="thirdParty.city"
                                            :options="citiesList"
                                            :searchable="true"
                                            :minChars="1"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :filterResults="true"
                                            :hide-selected="true"
                                            class="custom-multiselect userSelect"
                                            :classes="{ singleLabelText: 'text-sm overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full' }"
                                            :canClear="false"
                                            :disabled="thirdParty.country == '' || thirdParty.state == ''"
                                            placeholder="City"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdParty.city.$error,
                                                'has-options': citiesList && citiesList.length > 0,
                                                'no-options' :  !citiesList.length && thirdParty.city
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No City Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdParty.city.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.city.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.city.required && v$.thirdParty.city.alphabet.$invalid" class="invalid-feedback text-xs">City is Invalid</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">PIN Code</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdParty.zipCode"
                                            placeholder="PIN Code"
                                            :class="{
                                                'is-invalid': v$.thirdParty.zipCode.$error
                                            }"
                                            @input="v$.thirdParty.zipCode.$touch()" 
                                        />
                                        <div v-if="v$.thirdParty.zipCode.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.zipCode.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdParty.zipCode.required && v$.thirdParty.zipCode.zip.$invalid" class="invalid-feedback text-xs">Pin Code is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 1</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim ="thirdParty.addressLineOne"
                                            placeholder="Address Line 1"
                                            :class="{
                                                'is-invalid': v$.thirdParty.addressLineOne.$error
                                            }"
                                            @input = "v$.thirdParty.addressLineOne.$touch()"
                                        />
                                        <div v-if="v$.thirdParty.addressLineOne.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdParty.addressLineOne.required.$invalid">Required</div>
											<div v-else-if="v$.thirdParty.addressLineOne.required && v$.thirdParty.addressLineOne.restrictCharacters300.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 2 <span class="text-xs">(Optional)</span></label>
                                        <input type="text" class="inputboxstyling" v-model.trim ="thirdParty.addressLineTwo" placeholder="Address Line 2" :class="{'is-invalid': v$.thirdParty.addressLineTwo.$error}" @input = "v$.thirdParty.addressLineTwo.$touch()"/>
                                        <div v-if="v$.thirdParty.addressLineTwo.$error" class="text-red-500">
											<div v-if="v$.thirdParty.addressLineTwo.restrictCharacters300.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="addingObject == true" class="buttonposition flex justify-center items-center mb-4 mx-0">
                    <button class="btnprimary mr-1.5" :disabled="clickOnButton"  :class="JSON.stringify(thirdParty) === JSON.stringify(dummyThirdParty)  ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn(thirdParty._id, 20003)">Cancel</button>
                    <button class="btnprimary" :disabled="clickOnButton" :class="JSON.stringify(thirdParty) === JSON.stringify(dummyThirdParty)  ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdate(thirdParty, 20003)">Save</button>
                </div>
                <div v-if="addingObject == false" class="buttonposition flex justify-center items-center mb-4 mx-0">
                    <button class="btnprimary mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(thirdParty) === JSON.stringify(dummyThirdParty) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn(thirdParty._id, 20003, 'update')">Cancel</button>
                    <button class="btnprimary" :disabled="clickOnButton" :class="JSON.stringify(thirdParty) === JSON.stringify(dummyThirdParty) ? 'btndisabled pointer-events-none' : 'btnprimary '" @click="addOrUpdate(thirdParty, 20003)">Update</button>
                </div>
            </fieldset>
        </popup>

        <popup v-if="selectedIndex == 20004  && popUp" :popUp="popUp">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>Vendors Contacts</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="pr-2 w-full">
                                        <label class="controllabel">Third Party</label>
                                        <multiselect 
                                            v-model="thirdPartyContacts.thirdParty" 
                                            :options="thirdPartyOptions"
											@search-change="asyncFindThirdParty"
                                            @select="thirdPartyContactsSelect"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Third party"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.thirdPartyContacts.thirdParty.$error,
                                                'has-options': thirdPartyOptions && thirdPartyOptions.length > 0,
                                                'no-options': !thirdPartyOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Vendors Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdPartyContacts.thirdParty.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.thirdParty.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                    <div class="leftspacing w-full">
                                        <label class="controllabel">Type</label>
                                        <select v-model.trim="thirdPartyContacts.type" :class="{'is-invalid': v$.thirdPartyContacts.type.$error,'inputboxstylingForSelectAtPopup': thirdPartyContacts.type === '','stylingForInputboxstylingForSelectAtPopup': thirdPartyContacts.type !== ''}">
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="(value, index) in type" :key="index" :value="value.id" :disabled="disableTypeForVendorContacts && value.id == 1" :class="disableTypeForVendorContacts && value.id == 1 ? 'taskStatusOptionsForNotStarted' : 'taskStatusOptions'">{{ value.value }}</option>
                                        </select>
                                        <!-- <multiselect 
                                            v-model="thirdPartyContacts.type" 
                                            :options="ownerTypeValues"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            :disabled="thirdPartyPrimaryCondition == true && ownerTypeValues.value == 1"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Type"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.thirdPartyContacts.type.$error
                                            }"
                                        >
                                        </multiselect> -->
                                        <!-- <select
                                            class="inputboxstyling"
                                            v-model="thirdPartyContacts.type"
                                            :disabled="thirdPartyPrimaryCondition == true && ownerTypeValues.value == 1"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.type.$error
                                            }"
                                        >
                                            <option value="" selected disabled hidden>Please select one</option>
                                            <option v-for="obj in ownerTypeValues" :key="obj" :value="obj.value">{{ obj.label }}</option>
                                        </select> -->
                                        <div v-if="v$.thirdPartyContacts.type.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.type.required.$invalid">Required</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{ optionsUserArray }} -->
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Name</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdPartyContacts.fullName"
                                            placeholder="Name"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.fullName.$error
                                            }"
                                            @input = "v$.thirdPartyContacts.fullName.$touch"
                                        />
                                        <!-- <multiselect 
                                            v-model="thirdPartyContacts.fullName" 
                                            :options="userOptions"
                                            :showNoOptions="false"
                                            @search-change="asyncFind"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Name"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.thirdPartyContacts.fullName.$error
                                            }"
                                        >
                                        </multiselect> -->
                                        <div v-if="v$.thirdPartyContacts.fullName.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.fullName.required.$invalid">Required</div>
											<div v-else-if="v$.thirdPartyContacts.fullName.required && v$.thirdPartyContacts.fullName.restrictCharacters30.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Job Title</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdPartyContacts.role"
                                            placeholder="Job Title"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.role.$error
                                            }"
                                            @input = "v$.thirdPartyContacts.role.$touch"
                                        />
                                        <div v-if="v$.thirdPartyContacts.role.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.role.required.$invalid">Required</div>
											<div v-else-if="v$.thirdPartyContacts.role.required && v$.thirdPartyContacts.role.restrictCharacters30.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Email</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdPartyContacts.emailAddress"
                                            placeholder="Email"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.emailAddress.$error || checkMail
                                            }"
                                        />
                                        <div v-if="v$.thirdPartyContacts.emailAddress.$error || checkMail || isMailExist" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.emailAddress.required.$invalid">Email is required</div>
                                            <div v-else-if="v$.thirdPartyContacts.emailAddress.required && v$.thirdPartyContacts.emailAddress.email.$invalid" class="invalid-feedback text-xs">Email is Invalid</div>
											<p v-else-if="checkMail" class="error-text">email shouldn't be of same domain</p>
                                            <p v-else-if="isMailExist" class="error-text">Mail already exist</p>
											<div v-else-if="v$.thirdPartyContacts.emailAddress.required && v$.thirdPartyContacts.emailAddress.restrictCharacters60.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">Phone</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdPartyContacts.phoneNumber"
                                            placeholder="Phone"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.phoneNumber.$error
                                            }"
                                            @input="v$.thirdPartyContacts.phoneNumber.$touch()"
                                        />
                                        <div v-if="v$.thirdPartyContacts.phoneNumber.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.phoneNumber.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdPartyContacts.phoneNumber.required && v$.thirdPartyContacts.phoneNumber.numeric.$invalid" class="invalid-feedback text-xs">Phone Number is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{ countriesList }} -->
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">Country</label>
                                        <multiselect
                                            v-model="thirdPartyContacts.country"
                                            @select="changeCountry(thirdPartyContacts.country)"
                                            :options="countriesList"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"   
                                            :hide-selected="true" 
                                            :searchable="true"
                                            :hideArrow="false"
                                            class="custom-multiselect userSelect"
                                            :canClear="false"
                                            placeholder="Country"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdPartyContacts.country.$error,
                                                'has-options': countriesList && countriesList.length > 0,
                                                'no-options':  !countriesList.length
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Country Found</span>
                                        </template>
                                        </multiselect>

                                        <div v-if="v$.thirdPartyContacts.country.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.country.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdPartyContacts.country.required && v$.thirdPartyContacts.country.alphabet.$invalid" class="invalid-feedback text-xs">Country is Invalid</div>
                                        </div>
                                    </div>
                                    <!-- {{ statesList }} -->
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">State</label>
                                        <multiselect
                                            v-model="thirdPartyContacts.state"
                                            :options="statesList"
                                            :searchable="true"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"  
                                            :hide-selected="true"  
                                            :hideArrow="false"
                                            class="custom-multiselect userSelect"
                                            @select="changeState(thirdPartyContacts.state)"
                                            :disabled="thirdPartyContacts.country == ''"
                                            placeholder="State"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdPartyContacts.state.$error,
                                                'has-options': statesList && statesList.length > 0,
                                                'no-options':  !statesList.length
                                            }"
                                        >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No State Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdPartyContacts.state.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.state.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdPartyContacts.state.required && v$.thirdPartyContacts.state.alphabet.$invalid" class="invalid-feedback text-xs">State is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- {{citiesList}} -->
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-6/12 pr-2">
                                        <label class="controllabel">City</label>
                                        <multiselect
                                            v-model="thirdPartyContacts.city"
                                            :options="citiesList"
                                            :multiple="false"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            :hide-selected="true"
                                            open-direction="bottom"
                                            :disabled="thirdPartyContacts.country == '' || thirdPartyContacts.state == ''"   
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="City"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed': v$.thirdPartyContacts.city.$error,
                                                'has-options': citiesList && citiesList.length > 0,
                                                'no-options':  !citiesList.length

                                            }"
                                                
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No City Found</span>
                                        </template>
                                        </multiselect>
                                        <div v-if="v$.thirdPartyContacts.city.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.city.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdPartyContacts.city.required && v$.thirdPartyContacts.city.alphabet.$invalid" class="invalid-feedback text-xs">City is Invalid</div>
                                        </div>
                                    </div>
                                    <div class="w-6/12 leftspacing">
                                        <label class="controllabel">PIN Code</label>
                                        <input
                                            type="text"
                                            class="inputboxstyling"
                                            v-model.trim="thirdPartyContacts.zipCode"
                                            placeholder="PIN Code"
                                            :class="{
                                                'is-invalid': v$.thirdPartyContacts.zipCode.$error
                                            }"
                                            @input="v$.thirdPartyContacts.zipCode.$touch()" 
                                        />
                                        <div v-if="v$.thirdPartyContacts.zipCode.$error" class="text-red-500">
                                            <div class="error-text" v-if="v$.thirdPartyContacts.zipCode.required.$invalid">Required</div>
                                            <div v-else-if="v$.thirdPartyContacts.zipCode.required && v$.thirdPartyContacts.zipCode.zip.$invalid" class="invalid-feedback text-xs">Pin Code is Invalid</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="upperspacing w-full">
                                    <label class="controllabel">Address Line 1</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="thirdPartyContacts.addressLineOne"
                                        placeholder="Address Line 1"
                                        :class="{
                                            'is-invalid': v$.thirdPartyContacts.addressLineOne.$error
                                        }"
                                        @input = "v$.thirdPartyContacts.addressLineOne.$touch()"
                                    />
                                    <div v-if="v$.thirdPartyContacts.addressLineOne.$error" class="text-red-500">
                                        <div class="error-text" v-if="v$.thirdPartyContacts.addressLineOne.required.$invalid">Required</div>
                                        <div v-else-if="v$.thirdPartyContacts.addressLineOne.required && v$.thirdPartyContacts.addressLineOne.restrictCharacters300.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label class="controllabel">Address Line 2 <span class="text-xs">(Optional)</span></label>
                                        <input type="text" class="inputboxstyling" v-model.trim="thirdPartyContacts.addressLineTwo" placeholder="Address Line 2" :class="{  'is-invalid': v$.thirdPartyContacts.addressLineTwo.$error}" @input = "v$.thirdPartyContacts.addressLineTwo.$touch()"/>
                                        <div v-if="v$.thirdPartyContacts.addressLineTwo.$error" class="text-red-500">
                                            <div v-if="v$.thirdPartyContacts.addressLineTwo.restrictCharacters300.$invalid" class="text-error text-xs">Character Limit exceeded</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="addingObject== true" class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button class="btnprimary mr-1.5" :disabled="clickOnButton" :class="JSON.stringify(thirdPartyContacts) === JSON.stringify(dummyThirdPartyContact) ? 'btndisabled pointer-events-none' : 'btnprimary'"  @click="onCancelbtn(thirdPartyContacts._id, 20004)">Cancel</button>
                        <button class="btnprimary" :disabled="clickOnButton" :class="JSON.stringify(thirdPartyContacts) === JSON.stringify(dummyThirdPartyContact) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdate(thirdPartyContacts, 20004)">Save</button>
                    </div>
                    <div v-if="addingObject== false" class="buttonposition flex justify-center items-center mb-4 mx-0">
                        <button class=" mr-1.5" :disabled="clickOnButton" :class=" JSON.stringify(thirdPartyContacts) === JSON.stringify(dummyThirdPartyContact) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="onCancelbtn(thirdPartyContacts._id, 20004 , 'update')">Cancel</button>
                        <button :disabled="clickOnButton" :class=" JSON.stringify(thirdPartyContacts) === JSON.stringify(dummyThirdPartyContact) ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="addOrUpdate(thirdPartyContacts, 20004)">Update</button>
                    </div>
                </div>
            </fieldset>
        </popup>
    </div>
</template>
<style scoped>
.primary{
    color:#152a43 !important;
}
.borderRed {
    border: 1px solid red !important;
}
.anime-left {
    animation-name: moveLeft;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveLeft {
    0% {
        opacity: 1;
        transform: translateX(100vw);
    }
    100% {
        transform: translateX(0);
    }
}
.anime-right {
    animation-name: moveRight;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
}
@keyframes moveRight {
    0% {
        opacity: 1;
        transform: translateX(-100vw);
    }
    100% {
        transform: translateX(0);
    }
}
/* .progress {
    background: #152a43;
}
.progressbarB {
    border: 2px solid #e9e9e9;
    background: #e9e9e9;
    position: relative;
    top: -8px;
    border-radius: 2px;
} */
.setBox {
    width: initial;
}

.box {
    border: 2px solid red;
}
.button-adjust {
    position: relative;
    top: -30px;
    z-index: 1;
    cursor: pointer;
}
.borderBottom {
    position: relative;
    z-index: 2;
    cursor: pointer;
}
.businesssPopup label {
    font-size: 16px;
    font-family: Open Sans !important;
}
.taskStatusOptions{
  background-color: white;
  color: rgba(77, 77, 77, var(--tw-text-opacity))
}
.taskStatusOptionsForNotStarted{
background-color: #e9e9e9;
color: rgba(77, 77, 77, var(--tw-text-opacity))
}
</style>
<script lang="ts">
import { forEach } from 'lodash';
import { defineComponent } from 'vue';
import { required, maxLength, minLength, email, helpers, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { emitter , toast} from '@/main';
const numeric = helpers.regex(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
// const alphabet = helpers.regex(/^[a-zA-Z]+$/);
const alphabet = helpers.regex(/^\s*[a-zA-Z]+(?:\s+[a-zA-Z]+)*\s*$/);
const zip = helpers.regex(/^\d{5}(?:\d{1})?$/);
import dropdown from '@/components/dropdown.vue';
import XLSX from 'xlsx';
import popup from '@/components/popup.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import newTable from '@/components/newTable.vue';
import Multiselect from 'vue-multiselect';
import toolTip from '@/components/toolTip.vue';
const restrictCharacters300 = helpers.regex(/^.{0,300}$/);
const restrictCharacters20 = helpers.regex(/^.{0,20}$/);
const restrictCharacters30 = helpers.regex(/^.{0,30}$/);
const restrictCharacters60 = helpers.regex(/^.{0,60}$/);
// const matchUrl = (value: any, vm: any) => value.substring(value.indexOf('@') + 1).split('.')[0] !== vm.domainName;
const websiteValidate = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-zA-Z]{2,20}(\.(?!www)[a-zA-Z]{2,20}){0,2})(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);
const textOnly = helpers.regex(/^[a-zA-Z\s]*$/);

export default defineComponent({
    data(): any {
        return {
            v$: useVuelidate(),
            objToBeModified: {},
            dummyObj: {},
            companyNameList: [],
            companyNameListOne: [],
            companyType: [],
            companyTypeOne: [],
            showFilters: false,
            id: [],
            fullNames:['abhiram','jyiosthnad','idheghdukh'],
            autoSuggestion: false,
            selectedCategory: [],
            permissions: [],
            
            disablePrimaryContact : false,
            nameList: [],
            carouselHead: [
                // { displayName: 'Business Entity', name: 'businesss', id: 20001 },

                // { displayName: 'Business Entity Contacts', name: 'businessContacts', id: 20002 },

                { displayName: 'Vendors', name: 'thirdParty', id: 20003 },

                { displayName: 'Vendors Contacts', name: 'thirdPartyContacts', id: 20004 },
            
            ],
            index: 0,
            selectedIndex: null,
            selectedHead: 0,
            popUp: false,
            currentPage: 1,
            pageSize: 6,
            rowData: [],
            tableData : [],
            tableDataNew : [],
            thirdPartyPrimaryCondition: false,
            thirdPartyList: [],
            sideButton: false,
            data: {},
            tableRow1: [],
            tableRow2:[],
            presentUserObj: {},
            userOptions:[],
            entitiesListing : [],
            headers1: ['_id', 'Name', 'Display Name', 'Responsibility', 'Contract Start Date', 'Contract Expiry Date'],
            headers2: ['_id', 'Name', 'Job Title', 'type', 'Email', 'Phone', 'vendor'],
            businesss: {
                companyName: '',
                companyDisplayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                entityType: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            businessContacts: {
                companyName: '',
                contactType: '',
                name: '',
                role: '',
                emailAddress: '',
                phoneNumber: ''
            },
            thirdParty: {
                name: '',
                displayName: '',
                industrySectorName: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                responsibleBusinessEntity: '',
                city: '',
                state: '',
                zipCode: '',
                country: '',
                contractExpirationDate: '',
                contractStartDate: '',
                entitiesusethirdparty: [],
                website:'',
            },
            thirdPartyContacts: {
                thirdParty: '',
                type: '',
                fullName: '',
                role: '',
                emailAddress: '',
                phoneNumber: '',
                addressLineOne: '',
                addressLineTwo: '',
                city: '',
                state: '',
                zipCode: '',
                country: ''
            },
            vendorContactEmailsArray:[],
            filtervendorContactEmailsArray:[],
            editPop : false,
            editThirdPartyPop : false,
            countriesStatesCities: [
                {
                    CountryName: 'India',
                    States: [
                        {
                            StateName: 'Maharashtra',
                            Cities: ['Pune', 'Nagpur', 'Mumbai']
                        },
                        {
                            StateName: 'Kerala',
                            Cities: ['Kochi', 'Munnar']
                        },
                        {
                            StateName: 'Telangana',
                            Cities: ['Hyderabad', 'Nizamabad', 'Warangal']
                        },
                        {
                            StateName: 'Andhra Pradesh',
                            Cities: ['Vijayawada', 'Vizag', 'Rajamundry']
                        }
                    ]
                },
                {
                    CountryName: 'Australia',
                    States: [
                        {
                            StateName: 'Aukland',
                            Cities: ['GlenField', 'Henderson', 'MilFord']
                        },
                        {
                            StateName: 'Victoria',
                            Cities: ['Melbourne','Geelong','Ballarat']
                        },
                         {
                            StateName: 'Queensland',
                            Cities: ['Brisbane ','Cairns','Townsville']
                        }
                    ]
                }
            ],
            type: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Secondary'
                }
            ],
            systems: [],
            users: [],
            optionsUserArray: [],
            optionsUserArrayWithNames: [],
            riskRoles: [
                {
                    roleName: 'Root Admin',
                    roleId: 10206,
                    moduleId: 10003,
                    disabled: true
                },
                {
                    roleName: 'Client Admin',
                    roleId: 10207,
                    moduleId: 10003
                },
                {
                    roleName: 'User',
                    roleId: 10208,
                    moduleId: 10003
                }
            ],
            tenantsSelect: [],
            tenants: [],
            savedInformationSets: [],
            teams: [],
            cloud: ['AWS', 'Microsoft Azure', 'Google Cloud', 'DigitalOcean', 'IBM', 'Rackspace', 'Oracle', 'Red Hat', 'Alibaba'],
            departmentList: [],
            editBusiness: false,
            orgType: [
                {
                    id: 1,
                    value: 'Primary'
                },
                {
                    id: 2,
                    value: 'Subsidiary'
                },
                {
                    id: 3,
                    value: 'Branch'
                }
            ],
            ownerTypeValues: [
                {
                    value: 1,
                    label: 'Primary'
                },
                {
                    value: 2,
                    label: 'Secondary'
                }
            ],
            roles: [
				{
					roleName: 'Admin',
					roleId: 10207
				},
				{
					roleName: 'User',
					roleId: 10208
				}
			],
            checkDomainName:'',
            responsibleBP:{},
            entitiesList: [],
            rowDetails: {},
            countriesList: [],
            statesList: [],
            citiesList: [],
            stateInfoList: [],
            visibleTabs: [], // Tabs visible on screen/
            currentGroupIndex: 0, // Current group index for small screens
            tabsPerGroup: 3, // Number of tabs per group for small screens
            isSmallScreen: false, // Flag for screen size
            canScrollLeft: false, // Whether tabs can scroll left
            canScrollRight: true, // Whether tabs can scroll right
            totalGroups: 0 ,// Total number of groups for small screens
            getVendorData : [],
            dummyThirdParty:{},
            dummyThirdPartyContact:{},
            addingObject:false,
            thirdPartyOptions : [],
            toolTipActive : false,
            toolTipText : '',
            clickOnButton: false,
            // teamsDataArray:[],
            // userEmails:[],
            // tenantsList:[],
            existingVendors:[],
            filterExistingVendors:[],


            existingDisplayNames:[],
            filterDisplayNames:[],

            defaultUser: {
				uiroles: [10205, 10208],
				isActive: 0
			},
            vendorsListArray:[],
            vendorSelection:'',
            disableTypeForVendorContacts:false,
            dataLoading:true,
            searchValue: '',

        };
    },
    validations() {
        return {
            businesss: {
                companyName: { required },
                companyDisplayName: { required },
                industrySectorName: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric },
                addressLineOne: { required },
                entityType: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                country: { required, alphabet },
                zipCode: { required, zip }
            },
            businessContacts: {
                companyName: { required },
                contactType: { required },
                name: { required },
                role: { required },
                emailAddress: { required, email },
                phoneNumber: { required, numeric }
            },
            thirdParty: {
                name: { required,restrictCharacters20 },
                displayName: { required,restrictCharacters20 },
                industrySectorName: { required,restrictCharacters20 },
                emailAddress: { required, email,restrictCharacters60 },
                phoneNumber: { required, numeric },
                addressLineOne: { required ,restrictCharacters300},
                addressLineTwo: { restrictCharacters300 },
                responsibleBusinessEntity: { required },
                city: { required, alphabet },
                state: { required, alphabet },
                zipCode: { required, zip },
                country: { required, alphabet },
                contractExpirationDate: {
                    required,
                    minValue: helpers.withMessage('', (value: any) => {
                        return new Date(value) > new Date(this.thirdParty.contractStartDate);
                    })
                },
                website: {required, websiteValidate},
                contractStartDate: { required },
                entitiesusethirdparty: { required }
            },
            thirdPartyContacts: {
                thirdParty: { required },
                type: { required },
                fullName: { required,restrictCharacters30 },
                role: { required ,restrictCharacters30},
                emailAddress: { required, email,restrictCharacters60 },
                phoneNumber: { required, numeric },
                addressLineOne: { required,restrictCharacters300 },
                addressLineTwo: { restrictCharacters300 },
                city: { required, alphabet },
                state: { required, alphabet },
                zipCode: { required, zip },
                country: { required, alphabet }
            }
        };
    },
    async created() {
        this.visibleTabs = [...this.carouselHead];
        
        window.addEventListener('resize', this.handleResize);
        this.updateButtonStates();
        this.dataLoading = true;
        await this.getAllActiveUsers();
        this.countriesStatesCities.map((obj: any) => {
            this.countriesList.push(obj.CountryName);
        });
        let savedTabKey = sessionStorage.getItem('selectedTab');
        if (!savedTabKey) {
        savedTabKey = '20003';
        sessionStorage.setItem('selectedTab', savedTabKey);
        }
        console.log("savedTabKey", savedTabKey);
        const savedTab = this.carouselHead.find((tab: any) => tab.id == savedTabKey);
        if (savedTab) {
        await this.toggleTabs(savedTab.id);
        console.log("savedTab",savedTab);
        // this.getAllData(20003)
        // this.getAllData(20004)
        this.dataLoading = false;
    }
    },

    async mounted() {
        this.handleResize();
        
        await this.getBusinessEntities();
        await this.getAllTenants();
        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            let comingId = isData._id;  
            console.log("this.getVendorData",this.getVendorData);
            console.log("comingId",comingId);
            let myObj = this.getVendorData.find((obj:any)=>{return obj._id == comingId});

            if(this.selectedIndex == 20003){
                this.changeCountry(myObj.country);
                this.changeState(myObj.state);
                // let tenantdata = this.tenants.filter((tenant: any) => {
                //     return tenant.id == this.userInfo.organisationId;
                // });
                // myObj.domainName = tenantdata[0].name;
                // myObj.domainName = myObj.domainName.toLowerCase();
                this.thirdParty = {...myObj}
                this.dummyThirdParty = {...myObj};

                this.filterExistingVendors = this.existingVendors.filter((name:any)=>{return name.trim().replace(/\s+/g, '').toLowerCase() != myObj.name.trim().replace(/\s+/g, '').toLowerCase()});

                this.filterDisplayNames = this.existingDisplayNames.filter((name:any)=>{return name.trim().replace(/\s+/g, '').toLowerCase() != myObj.displayName.trim().replace(/\s+/g, '').toLowerCase()})
                
                // this.showPopupByADD(myObj,'edit')
            }else if(this.selectedIndex == 20004){
                if(myObj!=undefined && myObj!=null && myObj!= ''){
                    if(myObj.fullName != undefined && typeof myObj.fullName == 'string'){
                         let name: any=  this.optionsUserArray.find((obj: any)=>{
                            return obj.value == myObj.fullName
                        })
                        myObj.fullName = name ? name.label : myObj.fullName
                    }
                   

                    const editPopup:any = this.vendorsListArray.some((obj:any)=>{
                        
                                                
                        return obj.thirdParty.value == myObj.thirdParty.value && obj.type==1;
                    })                    
                    if(editPopup==true){
                        this.disableTypeForVendorContacts=true;
                    }
                    else if(editPopup==false){
                        this.disableTypeForVendorContacts = false;

                    }
                    this.changeCountry(myObj.country);
                    this.changeState(myObj.state);
                }

                this.filtervendorContactEmailsArray = this.vendorContactEmailsArray.filter((mail:any)=>{ return mail != myObj.emailAddress})
                // let tenantdata = this.tenants.filter((tenant: any) => {
                //     return tenant.id == this.userInfo.organisationId;
                // });
                // myObj.domainName = tenantdata[0].name;
                // myObj.domainName = myObj.domainName.toLowerCase();
                this.thirdPartyContacts = {...myObj}
                this.dummyThirdPartyContact = {...myObj}
                
                // console.log("tenantdata",tenantdata[0].name);
            }
            this.addingObject = false

            // this.showPopupByADD(myObj,'edit')
            this.popUp = true;
        });
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
        this.getSystems();
        // this.getInformationSets();
        this.getAllTeams();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
        sessionStorage.removeItem('selectedTab')
    },
    components: {
        newTable,
        popup,
        Multiselect,
        toolTip
    },
    computed: {
        ...mapGetters({ userInfo: 'userInfo', dbSecurityCategory: 'dbSecurityCategory' }),
        tabHead(): any {
            return this.carouselHead.filter((row: any, index: any) => {
                this.windowWidthMethod();
                let start = (this.currentPage - 1) * this.pageSize;
                this.index = 0;
                let end = this.currentPage * this.pageSize;

                if (index >= start && index < end) return true;
            });
        },
        disableStatus: function (): any {
            const deepTrim:any = (obj:any) => {
            if (typeof obj === 'string') {
                return obj.trim();
            } else if (Array.isArray(obj)) {
                return obj.map(deepTrim);
            } else if (typeof obj === 'object' && obj !== null) {
                return Object.fromEntries(
                Object.entries(obj).map(([key, value]) => [key, deepTrim(value)])
                );
            }
            return obj; // Return the value as is if it's neither a string nor an object
            };
        
            const thirdPartyContacts = deepTrim(this.thirdPartyContacts);
            const dummyThirdPartyContact = deepTrim(this.dummyThirdPartyContact);
        
            return JSON.stringify(thirdPartyContacts) === JSON.stringify(dummyThirdPartyContact);
        },
        // isObjectEmpty:function():any{
        //     let result = true;
        //     if(this.selectedIndex == 20003){
        //         let valuesarr:any = []
        //         valuesarr = Object.values(this.thirdParty);
        //         for (let val of valuesarr){
        //             console.log(":valllll",val);
                    
        //             if(val != '' ){
        //                 result = false;
        //                 break
        //             }
        //         }
        //     }else if(this.selectedIndex == 20004){
        //         let valuesarr = Object.values(this.thirdPartyContacts);
        //         for (let val of valuesarr){
        //             if(val != ''){
        //                 result = false;
        //                 break
        //             }
        //         }
        //     }

        //     return result
        // }
        checkMail: function (): any {
            let tenantdata = this.tenants.filter((tenant: any) => {
                return tenant.id == this.userInfo.organisationId;
            });
            this.checkDomainName = tenantdata[0].name.toLowerCase();
            if(this.selectedIndex == 20003){
                if(this.addingObject == true && this.thirdParty.emailAddress != undefined){
                let check: any = this.thirdParty.emailAddress.substring(this.thirdParty.emailAddress.indexOf('@') + 1).split('.')[0]
                let a = false;
                    a = (check === this.checkDomainName)
                    return a
                }else if(this.addingObject==false && this.thirdParty.emailAddress != undefined){
                    let check1: any = this.thirdParty.emailAddress.substring(this.thirdParty.emailAddress.indexOf('@') + 1).split('.')[0]
                    let a = false;
                    a = (check1 === this.checkDomainName)
                    return a
                }
            } else if(this.selectedIndex == 20004){
                if(this.addingObject == true && this.thirdParty.emailAddress != undefined){
                let check: any = this.thirdPartyContacts.emailAddress.substring(this.thirdPartyContacts.emailAddress.indexOf('@') + 1).split('.')[0]
                let a = false;
                    a = (check === this.checkDomainName)
                    return a
                }else if(this.addingObject==false && this.thirdParty.emailAddress != undefined){
                    let check1: any = this.thirdPartyContacts.emailAddress.substring(this.thirdPartyContacts.emailAddress.indexOf('@') + 1).split('.')[0]
                    let a = false;
                    a = (check1 === this.checkDomainName)
                    return a
                }
            }
            
        },
        isMailExist: function():any{
            let result = false;
            let a:any = [];

            if(this.addingObject == true){
                a  = [...this.vendorContactEmailsArray]
            }else{
                a = [...this.filtervendorContactEmailsArray]
            }

            if( a.includes(this.thirdPartyContacts.emailAddress)){
                result = true
            }
            return result;
        },
        doVendorExist: function():any{
            let result = false;
            let a:any = [];

            if(this.addingObject == true){
                a  = [...this.existingVendors]
            }else{
                a = [...this.filterExistingVendors]
            }

            if( a.includes(this.thirdParty.name.trim().replace(/\s+/g, '').toLowerCase()) && this.selectedIndex == 20003){
                result = true
            } else{
                result = false
            }
            return result;
        },
        doDisplayNameExist: function():any{
            let result = false;
            let a:any = [];

            if(this.addingObject == true){
                a  = [...this.existingDisplayNames]
            }else{
                a = [...this.filterDisplayNames]
            }

            if( a.includes(this.thirdParty.displayName.trim().replace(/\s+/g, '').toLowerCase()) && this.selectedIndex == 20003){
                result = true
            } else{
                result = false
            }
            return result;
        }
    },
    methods: {
        // checkMail(obj :any){
        //     let check: any = obj.substring(obj.indexOf('@') + 1).split('.')[0]
        //     let tenantdata = this.tenants.filter((tenant: any) => {
        //         return tenant.id == this.userInfo.organisationId;
        //     });
        //     this.checkDomainName = tenantdata[0].name.toLowerCase();
        //     console.log("this.checkDomainName",this.checkDomainName)
        //     console.log("check",check);
            
        //     if(check === this.checkDomainName){
        //         return true
        //     } else {
        //         return false
        //     }
        // },
        showPopupByADD(user: any, action: string){
            this.popUp = true;
            this.addingObject = true
            // let name = this.carouselHead.filter((x: any) => x.id == this.selectedIndex);
            // name = name[0].name;
            // this[name] = {};
            console.log("user",user);
            console.log("action",action);
            if(this.selectedIndex == 20003){
                this.thirdParty = { name: '',displayName: '' ,industrySectorName: '' ,emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',responsibleBusinessEntity: '',city: '',state: '',zipCode: '',country: '',contractExpirationDate: '',contractStartDate: '',entitiesusethirdparty: []}
                this.dummyThirdParty = { name: '',displayName: '' ,industrySectorName: '' ,emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',responsibleBusinessEntity: '',city: '',state: '',zipCode: '',country: '',contractExpirationDate: '',contractStartDate: '',entitiesusethirdparty: []}
                
                // let tenantdata = this.tenants.filter((tenant: any) => {
                //     return tenant.id == this.userInfo.organisationId;
                // });
                // console.log("tenantdata",tenantdata[0].name);
                // this.thirdParty.domainName = tenantdata[0].name;
                // this.dummyThirdParty.domainName = tenantdata[0].name;
                // this.thirdParty.domainName = this.thirdParty.domainName.toLowerCase();
                // this.dummyThirdParty.domainName =  this.dummyThirdParty.domainName.toLowerCase();

                console.log("this.thirdParty",this.thirdParty);
                
            }
            else if(this.selectedIndex == 20004){
                // this.disableTypeForVendorContacts = false;
                this.thirdPartyContacts = {thirdParty: '',type: '',fullName: '',role: '',emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',city: '',state: '',zipCode: '',country: ''}
                this.dummyThirdPartyContact = {thirdParty: '',type: '',fullName: '',role: '',emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',city: '',state: '',zipCode: '',country: ''}
                
                // let tenantdata = this.tenants.filter((tenant: any) => {
                //     return tenant.id == this.userInfo.organisationId;
                // });
                // console.log("tenantdata",tenantdata);
                
                // this.thirdPartyContacts.domainName = tenantdata[0].name;
                // this.dummyThirdPartyContact.domainName = tenantdata[0].name;
                // this.thirdPartyContacts.domainName = this.thirdPartyContacts.domainName.toLowerCase();
                // this.dummyThirdPartyContact.domainName  = this.dummyThirdPartyContact.domainName.toLowerCase();
            }

        },
        scrollTabs(direction:any) {
            if (direction === 'left' && this.currentGroupIndex > 0) {
                this.currentGroupIndex--;
            } else if (direction === 'right' && this.currentGroupIndex < this.totalGroups - 1) {
                this.currentGroupIndex++;
            }
            this.updateVisibleTabs();
        },
        updateButtonStates() {
            const tabContainer = this.$refs.tabContainer;
            if (tabContainer) {
                if (!this.isSmallScreen) {
                    this.canScrollLeft = tabContainer.scrollLeft > 0;
                    this.canScrollRight = tabContainer.scrollLeft + tabContainer.clientWidth < tabContainer.scrollWidth;
                } else {
                    this.canScrollLeft = this.currentGroupIndex > 0;
                    this.canScrollRight = this.currentGroupIndex < this.totalGroups - 1;
                }
            }
        },
        handleScroll() {
            this.updateButtonStates();
        },
        handleResize() {
            const width = window.innerWidth;
            if (width <= 350) {
                this.tabsPerScreen = 1;
            } else {
                this.tabsPerScreen = this.carouselHead.length; // Show all tabs for larger screens
            }

            this.isSmallScreen = (width <= 350); // For logic buttons ref

            // Calculate total groups
            this.totalGroups = Math.ceil(this.carouselHead.length / this.tabsPerScreen);

            // Determine the group containing the selected tab
            this.updateCurrentGroupIndex();
            this.updateVisibleTabs();
            this.updateButtonStates();
        },
        updateCurrentGroupIndex() {
            const selectedTabIndex = this.carouselHead.findIndex((tab:any) => tab.id === this.selectedIndex);
            if (selectedTabIndex >= 0) {
                this.currentGroupIndex = Math.floor(selectedTabIndex / this.tabsPerScreen);
            } else {
                this.currentGroupIndex = 0; // Reset to first group if selected tab not found
            }
        },

        updateVisibleTabs() {
            if (this.tabsPerScreen === this.carouselHead.length) {
                this.visibleTabs = [...this.carouselHead]; // Show all tabs
            } else {
                const startIdx = this.currentGroupIndex * this.tabsPerScreen;
                const endIdx = startIdx + this.tabsPerScreen;
                this.visibleTabs = this.carouselHead.slice(startIdx, endIdx);
            }
        },
        asyncFind(query:any){
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
        asyncFindThirdParty(query:any){
            this.searchValue = query;
			if(query == ''){
				this.thirdPartyOptions = []
			}else if(query.length > 0){
                this.thirdPartyOptions = []
                
				this.thirdPartyOptions = this.companyNameListOne.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
                console.log('thirdPartyOptions',this.thirdPartyOptions)
			}
		},
        thirdPartyContactsSelect(obj1:any){
            this.vendorSelection = obj1.value;

            const disableCheck:any = this.vendorsListArray.some((obj:any)=>{
                return obj.thirdParty.value == this.vendorSelection && obj.type ==1
                
            })
            if(disableCheck==true){
                this.disableTypeForVendorContacts = true;

            }
            else if(disableCheck==false){
                this.disableTypeForVendorContacts = false;

            }
		},
        asyncFindLocation(query:any){
            this.searchValue = query;
            if(query == ''){
				this.entitiesListing = []
			}else if(query.length > 0){
				this.entitiesListing = this.entitiesList.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
        },
        openCalendar(event:any) {
			event.target.showPicker();
		},
        changeCountry(Country: any) {
            console.log('countriesStatesCities',Country)
            this.statesList = [];
            this.stateInfoList = [];
            let findobj = this.countriesStatesCities.find((obj: any) => {
                return obj.CountryName == Country;
            });
            if(findobj != undefined){
                findobj.States.map((obj: any) => {
                    this.stateInfoList.push(obj);
                    // console.log('this.stateInfoList',this.stateInfoList)
                    this.statesList.push(obj.StateName);
                    // console.log('statesList',this.statesList)
                });
            }
            this.thirdPartyContacts.state = '';
            this.thirdPartyContacts.city = '';
            this.thirdParty.state = '';
            this.thirdParty.city = '';
        },
        changeState(state: any) {
            this.citiesList = [];
            if(state != undefined){
                let findobj = this.stateInfoList.find((obj: any) => {
                    return obj.StateName == state;
                });
                console.log('findobjfindobj',findobj)
                if(findobj != undefined){
                    findobj.Cities.map((city:any)=>this.citiesList.push(city))
                }
                this.thirdPartyContacts.city = '';
                this.thirdParty.city = '';
            }
        },

        getUserDetails(nameId: any, selectedIndexId: any) {
            let mainUser = this.users.find((user: any) => {
                return user._id == nameId;
            });
            if (selectedIndexId == 20002) {
                this.businessContacts.emailAddress = mainUser.email;
                this.businessContacts.role = mainUser.jobTitle;
            } else if (selectedIndexId == 20004) {
                this.thirdPartyContacts.emailAddress = mainUser.email;
                this.thirdPartyContacts.role = mainUser.jobTitle;
            }
        },



        async toggleTabs(navIndex: any) {
            if(navIndex == 20004){
                await this.getAllData(20003);
            }
            this.dataLoading = true;
            this.index = navIndex;
            this.selectedIndex = navIndex;
            this.tableRow1 = [];
            this.tableRow2 = [];
            await this.getAllData(navIndex);
            console.log("fldkghfkdjlshg",navIndex);
            this.showFilters = false;
            this.dataLoading = false;
            emitter.emit('visible', this.showFilters);
            sessionStorage.setItem('selectedTab', navIndex);
        },

        async getAllData(entityId: number) {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                console.log('result.data',result.data)
                this.getVendorData = result.data;
                this.vendorsListArray = result.data;
                this.tableRow1 = [];
                this.dataLoading = true;
                
                switch (entityId) {
                    case 20003:
                    // this.headers1 = ['_id', 'Name', 'Display Name', 'Responsibility', 'Contract Start Date', 'Contract Expiry Date'];
                    this.companyNameListOne = []
                    this.existingVendors = [];

                    this.existingDisplayNames = []
                    this.getVendorData.forEach((entity: any) => {
                        let properties = {value: entity._id, label: entity.name}
                        this.companyNameListOne.push(properties)
                        console.log("entityentity",entity);
                       
                        if(entity.name != undefined && !this.existingVendors.includes(entity.name.toLowerCase())){
                            this.existingVendors.push(entity.name.trim().replace(/\s+/g, '').toLowerCase())
                        }

                        if(entity.displayName != undefined && !this.existingDisplayNames.includes(entity.displayName.toLowerCase())){
                            this.existingDisplayNames.push(entity.displayName.trim().replace(/\s+/g, '').toLowerCase())
                        }
         
                            var mainCompany = this.entitiesList.find((entityObj: any) => {
                                console.log('Property',this.companyNameListOne)
                                this.companyTypeOne.push(entity.entityType);
                                 return entityObj.value == entity.responsibleBusinessEntity
                            });
                            entity.responsibleBusinessEntity = mainCompany;

                            if(entity.entitiesusethirdparty != undefined){
                                entity.entitiesusethirdparty.map((id:any,index:any)=>{
                                    let systemObj = this.entitiesList.find((obj:any)=>{return obj.value == id});
                                    if(systemObj!=undefined){
                                        entity.entitiesusethirdparty[index] = systemObj
                                    }
                                })
                            }
                            this.tableRow1.push({
                                _id: entity._id,
                                entityId: entityId,
                                Name: entity.name!= undefined ? entity.name : 'N/A',
                                'Display Name': entity.displayName != undefined ? entity.displayName : 'N/A',
                                Responsibility: mainCompany != undefined ? mainCompany.label : 'N/A',
                                'Contract Start Date': entity.contractStartDate!= undefined ? entity.contractStartDate : 'N/A',
                                'Contract Expiry Date': entity.contractExpirationDate!= undefined ? entity.contractExpirationDate : 'N/A'
                                
                            });
                            this.dataLoading = false;
                        })
                        console.log('TABLEDATA',this.tableRow1)
                        console.log('DATA',this.getVendorData)
                        break;
                            case 20004:
                            this.tableRow2 = [];
                            this.vendorContactEmailsArray = [];
                            this.thirdPartyPrimaryCondition = false;
                            console.log('DATA22', this.getVendorData);
                            // this.headers2 = ['_id', 'Name', 'Job Title', 'type', 'Email', 'Phone', 'vendor'];
                            // this.userEmails = [];
                            this.getVendorData.forEach((entity: any) => {
                                console.log('ENTITY', entity);
                                let fullNameNew = 'N/A';  // Default value
                            
                                // Check if entity.fullName is defined
                                if (entity.fullName) {
                                    if (typeof entity.fullName === 'string') {
                                        // If it's a string, check if it's an ID or not
                                        let potentialFullName = this.optionsUserArray.find((object: any) => {
                                        return object.value === entity.fullName; // Check if it's an ID
                                        });
                                
                                        // If it's a valid ID, use the label, otherwise keep the string
                                        fullNameNew = potentialFullName ? potentialFullName.label : entity.fullName;
                                    } else {
                                        // In case it's some other type, default to 'N/A'
                                        fullNameNew = 'N/A';
                                    }
                                }

                                if(entity.emailAddress != undefined && entity.emailAddress!= '' && entity.emailAddress != null ){
                                    this.vendorContactEmailsArray.push(entity.emailAddress)
                                }
                                
                                var newThirdParty = this.companyNameListOne.find((third: any) => {
                                return third.value == entity.thirdParty;
                                });
                            
                                var i = this.companyNameListOne.indexOf(entity.name);
                                entity.entityType = this.companyTypeOne[i];
                                entity.thirdParty = newThirdParty;
                            console.log("this.tableRow2",entity);
                            
                                this.tableRow2.push({
                                _id: entity._id,
                                entityId: entityId,
                                vendor:entity.thirdParty !=  undefined ? entity.thirdParty.label : 'N/A',
                                Name: fullNameNew,  // Use the resolved fullNameNew value
                                'Job Title': entity.role != undefined ? entity.role : 'N/A',
                                type: entity.type != undefined ? entity.type : 'N/A',
                                'Email': entity.emailAddress != undefined ? entity.emailAddress : 'N/A',
                                'Phone': entity.phoneNumber != undefined ? entity.phoneNumber : 'N/A',
                                });
                                this.dataLoading = false;
                            console.log("this.tableRow2",this.tableRow2);
                            
                                if (entity.type == 1) {
                                this.disablePrimaryContact = true;
                                }
                            });
                            
                            console.log("this.companyNameListOne", this.companyNameListOne);
                            break;

            }
            })
        },
        async addOrUpdate(mainObject : any, id : any){
                console.log("mainObject",mainObject);
            
                
                let name = this.carouselHead.filter((x: any) => x.id == id);
                name = name[0].name;
                this.v$[name].$touch();

                let copyOfMainObj:any = _.cloneDeep(mainObject);
                let comingObjectId = copyOfMainObj._id;

                if(mainObject.emailAddress != undefined){
                    mainObject.emailAddress = mainObject.emailAddress.toLowerCase();
                }

                if (!this.v$[name].$invalid && !this.checkMail && this.isMailExist == false && this.doVendorExist == false && this.doDisplayNameExist == false ) {

                    this.clickOnButton = true;
                    // console.log('GOOD !!')
                    if (comingObjectId) {
                        try{
                            delete copyOfMainObj._id;
                            // delete copyOfMainObj.domainName
                            copyOfMainObj.updatedAt = new Date();

                            switch (id) {
                                case 20003:
                                    this.editThirdPartyPop = false;
                                    copyOfMainObj.responsibleBusinessEntity = copyOfMainObj.responsibleBusinessEntity.value;
                                    if(copyOfMainObj.entitiesusethirdparty != undefined){
                                        copyOfMainObj.entitiesusethirdparty.map((object:any,index:any)=>{
                                            copyOfMainObj.entitiesusethirdparty[index] = object.value
                                        });
                                    }
                                break
                                case 20004:
                                    this.editPop = false;
                                    // copyOfMainObj.fullName = copyOfMainObj.fullName.value;
                                    copyOfMainObj.thirdParty = copyOfMainObj.thirdParty.value;
                                    // copyOfMainObj.type = copyOfMainObj.type;
                                break
                            }
                            console.log("POST METHOD",comingObjectId)
                            await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/update/${comingObjectId}/${id}`, copyOfMainObj).then((result: any) => {
                                console.log('result',result.data)
                                this.popUp = false;
                                toast.info('updated', {
                                    timeout: 1000,
                                    closeOnClick: true,
                                    closeButton: 'button',
                                    icon: true
                                });
                            });
                            this.getAllData(id);
                            this.disablePrimaryContact = false
                            this.v$.$reset();
                        } catch{
                            toast.error(`cannot updated`, {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } finally {
                            this.clickOnButton = false;
                        } 
                    }else{
                        try{
                            let payload = _.cloneDeep(copyOfMainObj);
                                payload.createdAt = new Date();
                                if (Object.keys(payload).includes('_id')) {
                                    delete payload._id;
                                }
                                // if (Object.keys(payload).includes('domainName')) {
                                //     delete payload.domainName;
                                // }
                                if (Object.keys(payload).includes('updatedAt')) {
                                    delete payload.updatedAt;
                                }
                                switch (id) {
                                    case 20003:
                                    payload.responsibleBusinessEntity = payload.responsibleBusinessEntity.value;
                                    payload.entitiesusethirdparty.map((object:any,index:any)=>{
                                        payload.entitiesusethirdparty[index] = object.value
                                    });
                                    break
                                    case 20004:
                                    // payload.fullName = payload.fullName.value;
                                    payload.thirdParty = payload.thirdParty.value;
                                    // payload.type = payload.type;
                                    break
                                }
                                console.log("payload",payload);
                                
                                await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/create/${id}`, payload).then((result: any) => {
                                    toast.info('created', {
                                        timeout: 1000,
                                        closeOnClick: true,
                                        closeButton: 'button',
                                        icon: true
                                    });
                                });

                                console.log('PAYLOAD',payload)
                            
                            this.tableRow2 = [];
                            
                            this.getAllData(id);
                            this.popUp = false;
                            if(id == 20003){
                                this.v$.thirdParty.$reset();
                            }else if(id == 20004){
                                
                                this.v$.thirdPartyContacts.$reset();
                            }
                            this.v$.$reset();
                        } catch{
                            toast.error(`cannot updated`, {
                                timeout: 1000,
                                closeOnClick: true,
                                closeButton: 'button',
                                icon: true
                            });
                        } finally {
                            this.clickOnButton = false;
                        } 
                        }
                        
                }
            //     else {
            //     toast.error(`cannot save`, {
            //         timeout: 5000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
                
        },
        onCancelbtn(status:any , id:any , action : any){
          console.log('STATUS',action)
          if(status == 'close'){
            console.log('ADD')
            // if(id == 20003){
            //     con
            //      this.v$.thirdParty.$reset();
            //        this.thirdParty = {...this.dummyThirdParty}
            // }else if(id == 20004){
            //         this.thirdPartyContacts = {...this.dummyThirdPartyContact}
            //          this.v$.$reset();
            // }
            //  this.dummyThirdPartyContacts = {thirdParty: '',type: '',fullName: '',role: '',emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',city: '',state: '',zipCode: '',country: ''}
            this.v$.$reset();
            this.popUp = false;
            this.addingObject = false;

                if(id == 20003){
                    Object.keys(this.thirdParty).forEach((prop) => {
                        if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                            delete this.
                            thirdParty[prop];
                        } else {
                            Array.isArray(this.
                            thirdParty[prop]) ? (this.thirdParty[prop] = []) : (this.thirdParty[prop] = '');
                        }
                    });
                }else if(id == 20004){
                Object.keys(this.thirdPartyContacts).forEach((prop) => {
                    if (prop == '_id' || prop == 'createdAt' || prop == 'updatedAt') {
                        delete this.
                        thirdPartyContacts[prop];
                    } else {
                        Array.isArray(this.
                        thirdPartyContacts[prop]) ? (this.thirdPartyContacts[prop] = []) : (this.thirdPartyContacts[prop] = '');
                    }
                });
            }
          }
          else if(id == 20003){
            console.log('UPDATE')
            if(action == 'update'){
                this.changeCountry(this.dummyThirdParty.country);
                this.changeState(this.dummyThirdParty.state);
            }
            this.thirdParty = {...this.dummyThirdParty}
            this.v$.thirdParty.$reset();
            // this.popUp = false;
          }else if(id == 20004){
            if(action == 'update'){
                this.changeCountry(this.dummyThirdPartyContact.country);
                this.changeState(this.dummyThirdPartyContact.state);
                this.thirdPartyContacts = {...this.dummyThirdPartyContact}
                this.v$.thirdPartyContacts.$reset();
            }
             if(this.addingObject == true){
                console.log('HERE')
                  this.thirdPartyContacts = {thirdParty: '',type: '',fullName: '',role: '',emailAddress: '',phoneNumber: '',addressLineOne: '',addressLineTwo: '',city: '',state: '',zipCode: '',country: ''}
                //   this.v$.thirdPartyContacts.$reset();
                this.v$.$reset();
                // let tenantdata = this.tenants.filter((tenant: any) => {
                //     return tenant.id == this.userInfo.organisationId;
                // });
                // this.thirdPartyContacts.domainName = tenantdata[0].name;
                // this.dummyThirdPartyContact.domainName = tenantdata[0].name;
                // this.thirdPartyContacts.domainName = this.thirdPartyContacts.domainName.toLowerCase();
                // this.dummyThirdPartyContact.domainName  = this.dummyThirdPartyContact.domainName.toLowerCase();
            }

            // this.popUp = false;
          }
        },

        filterOpen() {
            this.showFilters = !this.showFilters;
            emitter.emit('visible', this.showFilters);
        },
        async getBusinessEntities() {
            this.entitiesList = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((result: any) => {
                result.data.map((obj: any) => {
                    let objj = { value: obj._id, label: obj.entityName };
                    this.entitiesList.push(objj);
                });
            });
        },
        async getAllActiveUsers() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
                this.users = res.data;
                console.log("USERS IN ORG",res.data)
            });
            for (var i = 0; i < this.users.length; i++) {
                this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
                this.optionsUserArrayWithNames.push({ label: this.users[i].name});

            }
        },
        async getSystems() {
            let entityId = '20005';
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/getall/${entityId}`).then((result: any) => {
                console.log('entityId',entityId)
                console.log('getSystems',result.data)
                this.systems = result.data;
                this.dummySystems = result.data;
                this.systems = this.systems.map((sys: any) => {
                    return { value: sys._id, label: sys.systemName };
                });
            });
        },
        async getAllTenants() {
            await this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
                res.data.map((org: any) => {
                    const obj = { id: org._id, name: org.organizationName };
                    this.tenants.push(obj);
                    const object = { value: org._id, label: org.organizationName };
                    this.tenantsSelect.push(object);
                });
            });
        },
        
        async getAllTeams() {
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
                for (var i = 0; i < res.data.length; i++) {
                    this.departmentList.push(res.data[i].teamName);
                }
            });
            console.log('this.departmentList', this.departmentList);
        },
        
    },
});
</script>
