<template>
	<div class="overflow-x-hidden overflow-y-hidden px-5 pt-3">
		<div>
			<breadCrumbs :routesArray="routesArray"></breadCrumbs>
		</div>
		<div class="flex justify-between items-center">
			<h1 class="title">Entites</h1>
			<div class="flex items-center">
                <button v-if="Object.keys(this.$route.params).includes('regionId')" class="mr-2" :disabled="clickOnButton" @click="onCancelButton('cancel')" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled h-8 pointer-events-none':'h-8 btnprimary'">Cancel</button>
                <button v-if="Object.keys(this.$route.params).includes('regionId')" class="mr-2" @click="updateRegionData" :disabled="clickOnButton" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none h-8':'btnprimary h-8'">Update</button>
                <button class="btnprimary mr-2" v-if="!Object.keys(this.$route.params).includes('regionId')" @click="addNewEntity(entitiesObj, 'add')">Add</button>
				<button @click="filterOpen" class="flex" :class="entitesDataArray.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
					Filters
					<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
				</button>
			</div>
		</div>
		<div class="mt-3 fontSizeInPage">
				The Global Risks Report 2022, 17th Edition, is published by the World Economic Forum. The information in this report, or on which this report is based, has been obtained from sources that the authors believe to be reliable and accurate. However, it has not been independently verified
				and no representation or warranty, express or implied, is made as to the accuracy or completeness of any information obtained from third parties.
		</div>
		<div class="w-full h-auto bg-white p-4 border border-lightgrey mt-5 rounded-sm" v-if="Object.keys(this.$route.params).includes('regionId')">
			 <div>
                <!-- {{regionObj}} ---{{dummyRegionObject}} -->
                    <div>
                        <div class="statuscontainer">
                                <div class="formcontainer flex flex-col">
                                    <div class="row flex flex-row w-full">
                                        <div class="w-full">
                                            <label class="controllabel font-semibold">Region Name</label>
                                            <input
                                                type="text"
                                                class="inputboxstyling labelMarginTop fontSizeInpopup"
                                                v-model.trim="regionObj.region"
                                                placeholder="Region Name"
                                                :class="{
                                                    'is-invalid': (v$.regionObj.region.$error || checkRegionName)
                                                }"
                                            />
                                            <div v-if="v$.regionObj.region.$error || checkRegionName" class="text-red-500">
                                                <div class="error-text" v-if="v$.regionObj.region.required.$invalid">Required</div>
                                                <div v-else-if="checkRegionName" class="error-text text-xs">Region Name already exists.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex flex-row w-full mt-3">
                                        <div class="w-6/12 ">
                                            <label class="controllabel font-semibold">Head Quarters (Optional)</label>
                                             <multiselect
                                                v-model="regionObj.regionHeadQuaters"
                                                :options="locationOptions"
                                                @focus="showAllOptions"
                                                open-direction="bottom"
                                                :multiple="false"
                                                :close-on-select="true"
                                                :hide-selected="true"
                                                :clear-on-select="true"
                                                label="label"
                                                track-by="value"
                                                :searchable="true"
                                                :hideArrow="false"
                                                placeholder="Head Quarters"
                                                class="custom-multiselect userSelect"
                                                :class="{
                                                'has-options': locationOptions && locationOptions.length > 0,
                                                'no-options':  !locationOptions.length
                                            }"
                                            >
                                             <template v-slot:noResult>
                                                <span class="text-xs">No Entites Found</span>
                                            </template>
                                          </multiselect>
                                        </div>
                                        <div class="w-6/12 ml-4">
                                            <label class="controllabel font-semibold">Region Owner</label>
                                            <multiselect 
                                            v-model="regionObj.regionOwner" 
                                            :options="userOptions"
											@search-change="asyncFind"
                                            :showNoOptions="false"
                                            :multiple="false"
                                            :hide-selected="true"
                                            :close-on-select="true"
                                            :clear-on-select="true"
                                            open-direction="bottom"
                                            label="label"
                                            track-by="value"    
                                            :searchable="true"
                                            :hideArrow="false"
                                            placeholder="Region Owner"
                                            class="custom-multiselect userSelect"
                                            :class="{
                                                'is-invalid borderRed':  v$.regionObj.regionOwner.$error,
                                                'has-options': userOptions && userOptions.length > 0,
                                                'no-options': !userOptions.length &&  searchValue !== '',
                                            }"
                                        >
                                         <template v-slot:noResult>
                                            <span class="text-xs">No Members Found</span>
                                        </template>
                                        </multiselect>
                                                <div v-if="v$.regionObj.regionOwner.$error" class="text-red-500">
                                                    <div class="error-text" v-if="v$.regionObj.regionOwner.required.$invalid">Required</div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <!-- <div class="buttonposition flex justify-center items-center my-4 mx-0">
                        <button class="mr-1.5" :disabled="clickOnButton" @click="onCancelButton('cancel')" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled h-8 pointer-events-none':'h-8 btnprimary'">Cancel</button>
                        <button @click="updateRegionData" :disabled="clickOnButton" :class="(JSON.stringify(dummyRegionObject)==JSON.stringify(regionObj))?'btndisabled pointer-events-none h-8':'btnprimary h-8'">Update</button>
                    </div> -->
                </div>

                    <div class="pb-1 font-semibold border-b border-lightgrey flex justify-between mt-3">
                        <div class="font-semibold flex items-center justify-center">Entities</div>
                        <button class="btnprimary mr-2"  @click="addNewEntity(entitiesObj, 'add')">Add</button>
                    </div>
			<newTable :tableRowData="entitesDataArray" :headersData="headersForRegions" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
		<div v-else>
			<newTable :tableRowData="entitesDataArray" :headersData="headersForEntities" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>


        <popup v-if="showTeamsPopup" :popUp="showTeamsPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between py-4 px-5 rounded">
                    <h1>{{selectedEntity.entityName}}</h1>
                    <button
                        class="float-right text-3xl"
                        @click="onCrossButton()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                    <div class="statuscontainer pt-3 px-5">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full">
                                <div class="w-full">
                                    <label class="controllabel font-semibold">Teams</label>
                                    <div v-if="teamDetailsForEntity.length > 0" class="flex flex-wrap">
                                        <div v-for="member in teamDetailsForEntity" :key="member" class="m-2">
                                            <div class="w-auto h-6 px-2 bg-primary rounded-sm flex items-center text-white text-sm">
                                                {{member.label}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <label class="font-normal">No Teams For This Entity</label>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </fieldset>
        </popup>
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import newTable from '@/components/newTable.vue';
import breadCrumbs from '@/components/breadCrumbs.vue';
import toolTip from '@/components/toolTip.vue'
import useVuelidate from '@vuelidate/core';
import Multiselect from 'vue-multiselect';
import { required } from '@vuelidate/validators';
import { emitter, toast } from '@/main';
import popup from '@/components/popup.vue'

export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			headersForEntities: ['entityName', 'entityDisplayName', 'regionName', 'entityType', 'ownerName','Action'],
			headersForRegions: ['entityName', 'entityDisplayName', 'entityType', 'ownerName',],
			showEntitesPopUp: false,
			entitesDataArray: [],
			showFilters: false,
			optionsUserArray:[],
			users:[],
			routesArray: [],
			regionDataArray :[],
			actualRegion:'',
			toolTipActive:false,
			toolTipText:'',
			regionObj:{
                region:'',
                regionHeadQuaters:'',
                regionOwner:'',
            },
			regionNamesExcludingPresentSystem : [],
			locationOptions : [],
			filteredEntities : [],
            userOptions :[],
			dummyRegionObject:{},
			clickOnButton: false,
			entityArray : [],
            dataLoading:true,
            teamDetailsForEntity:[],
            selectedEntity:'',
            showTeamsPopup:false,
            searchValue:'',
		};
	},
	components: {
		newTable,
		breadCrumbs,
		toolTip,
		Multiselect,
		popup
	},
    validations(){
        return{
            regionObj:{
                region: {required},
                // regionHeadQuaters: {required},
                regionOwner: {required},
            }
        }
    },
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		checkRegionName: function (): any {
            const trimmedRegionName = this.regionObj.region.trim().toLowerCase();
            if(this.regionDataArray == null || this.regionDataArray == undefined || this.regionDataArray.length == 0){
                return false
            }
             if(this.regionDataArray.length > 0){
               let a = false;
               a = this.regionNamesExcludingPresentSystem.map((name: any) => name.toLowerCase()).includes(trimmedRegionName);
                return a
            }
        },
        showAllOptions:function():any {
        this.filteredEntities = [];
        const selectedValue = this.regionObj?.regionHeadQuaters;
        this.entitesDataArray.map((obj:any)=>{
                if(obj.region == this.regionObj._id){
                    this.filteredEntities.push({value : obj._id , label : obj.entityName})
                }
            })
        this.locationOptions = this.filteredEntities.filter((option:any) => option.value !== selectedValue?.value);
        },
	},
	methods: {
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
		},
		addNewEntity(){
			if(Object.keys(this.$route.params).includes('regionId')){
				this.$router.push({name:'addEntityForRegions',params:{regionId : this.$route.params.regionId},query:{addAtRegions:true}})
			}else{
              this.$router.push({name:'addEntity'})
			}
		},
		async getAllActiveUsers() {
            this.optionsUserArray = []
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/active/getall`).then((res: any) => {
                this.users = res.data;
                for (var i = 0; i < this.users.length; i++) {
                   this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
                }
            });           
        },
		async getAllEntities(){
			this.entitesDataArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				if(Object.keys(this.$route.params).includes('regionId')){
					res.data.map((obj:any)=>{
						if(obj.region == this.$route.params.regionId){
							this.entitesDataArray.push(obj)
							this.entityArray.push({value : obj._id , label : obj.entityName})
						}
					})
				}else{
					this.entitesDataArray = res.data;
				}

				this.entitesDataArray.map((entity:any)=>{
					let userDetails = this.optionsUserArray.find((obj:any)=>{return obj.value == entity.owner});  
					entity.ownerName = userDetails != undefined ? userDetails.label : 'N/A'; 
				})
				this.entitesDataArray.map((entity:any)=>{
					let userDetails = this.regionDataArray.find((obj:any)=>{return obj._id == entity.region}); 
					console.log('ww',userDetails) 
					entity.regionName = userDetails != undefined ? userDetails.region : 'N/A'; 
				})
				console.log('asd',this.entitesDataArray)
                             
            })
		},
		 async getAllRegions(){
			this.actualRegion ='';
            let entities:any = []
             await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                entities = res.data;
             })
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
                console.log('sucesssss');
				this.regionDataArray = res.data;
				this.regionDataArray.map((region : any)=>{
					if(region != undefined && region._id == this.$route.params.regionId){
					 this.actualRegion = region.region;
				   }
				   console.log("ACTUAL",this.actualRegion)
                
				   var RegionOwner = this.optionsUserArray.find((entityObj: any) => {
					return entityObj.value == region.regionOwner
					});
					region.regionOwner = RegionOwner;
					
					if(region.regionHeadQuaters != undefined && region.regionHeadQuaters != null){  
						var RegionHeadQuaters:any = entities.find((entity:any)=>{
							return entity._id == region.regionHeadQuaters
					    })
					    if(RegionHeadQuaters != undefined && RegionHeadQuaters._id && RegionHeadQuaters.entityDisplayName){
                            region.regionHeadQuaters = {value:RegionHeadQuaters._id,label:RegionHeadQuaters.entityDisplayName};
                        }
					}else if((region.regionHeadQuaters != undefined && region.regionHeadQuaters == null) || region.regionHeadQuaters == ''){
                       region.regionHeadQuaters = ''
                    }
					this.dummyRegionObject = {...this.regionObj}
                    // this.editRegion(region)
				})

				});
		 },
		 asyncFindForLocation(query:any){
            this.filteredEntities = []
            this.entitesDataArray.map((obj:any)=>{
                if(obj.region == this.regionObj._id){ 
                    this.filteredEntities.push({value : obj._id , label : obj.entityName})
                }
            })
			if(query == ''){
				this.locationOptions = []
			}else if(query.length > 0){
				this.locationOptions = this.filteredEntities.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		asyncFind(query:any){
            this.searchValue = query;
			if(query == ''){
				this.userOptions = []
			}else if(query.length > 0){
				this.userOptions = this.optionsUserArray.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
		},
		 editRegion(comingObj:any){
			// console.log('comingObj',comingObj)
			// var RegionOwner = this.optionsUserArray.find((entityObj: any) => {
			// 		return entityObj.value == comingObj.regionOwner
			// });
            // comingObj.regionOwner = RegionOwner;
			// if(comingObj.regionHeadQuaters != undefined){  
			// 	var RegionHeadQuaters = this.entityArray.find((entity:any)=>{
			//     	return entity.value == comingObj.regionHeadQuaters
			//    })
			//    console.log('RegionHeadQuaters',RegionHeadQuaters)
			//    comingObj.regionHeadQuaters = RegionHeadQuaters;
			// }
            // this.dummyRegionObject = {region:'',regionHeadQuaters:'',regionOwner:'',_id:''}
            let keys:any = ['_id','region','regionHeadQuaters','regionOwner']
            for(const i in keys){
                this.regionObj[keys[i]] = comingObj[keys[i]];
                this.dummyRegionObject[keys[i]] = comingObj[keys[i]];
            }
            // console.log('dummyRegionObject',this.dummyRegionObject)
            
        },
		onCancelButton(){
			this.regionObj = {...this.dummyRegionObject}
		},
        onCrossButton(){
            this.showTeamsPopup = false;
            this.teamDetailsForEntity = [];
             this.selectedEntity = '';
        },
		 async updateRegionData(){
            this.v$.regionObj.$touch();
            if (!this.v$.regionObj.$invalid && this.checkRegionName == false) { 
                this.clickOnButton = true;
                let copyOfRegionObject :any = {...this.regionObj};
                let regionId = copyOfRegionObject._id;
                delete copyOfRegionObject._id   
                copyOfRegionObject.regionOwner = copyOfRegionObject.regionOwner.value     
                console.log('CONSOLE',copyOfRegionObject) ;
                 if(copyOfRegionObject.regionHeadQuaters != undefined){
                    copyOfRegionObject.regionHeadQuaters = copyOfRegionObject.regionHeadQuaters.value 
                }else if((copyOfRegionObject.regionHeadQuaters != undefined && copyOfRegionObject.regionHeadQuaters == null) || copyOfRegionObject.regionHeadQuaters == '' || copyOfRegionObject.regionHeadQuaters == null){
                     copyOfRegionObject.regionHeadQuaters = ''
                }
                console.log('$$',copyOfRegionObject) ; 
                let payload = {};
                payload = {...copyOfRegionObject};      
                try{
                    await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/region/${regionId}/update`, payload).then(async (res: any) => {
                        console.log('sucesssss')
                        toast.info('Updated', {
                            timeout: 1000,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                        });
                        await this.getAllRegions();
                        this.breadCrumbUpdate()
                    })
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                } finally {
                    this.clickOnButton = false;
                }
                
            }
            // else{
            //     toast.error(`Cannot Update`, {
            //         timeout: 1000,
            //         closeOnClick: true,
            //         closeButton: 'button',
            //         icon: true
            //     });
            // }
        },
        breadCrumbUpdate(){
            this.routesArray = [
				{ name: 'Regions', routeName: 'regions' },
				{ name: this.actualRegion, routeName: 'businessEntitesForRegion' }
			];
        },
        async getTeamsForEntity(payload:any){
            this.teamDetailsForEntity = [];

            await this.$http.post(`${process.env.VUE_APP_ORG_API_URL}/api/org/entities/teams/getall`,payload).then((res: any) => {
				let a:any = res.data;
                a.map((obj:any)=>{
                    let newObj:any = {value : obj._id , label : obj.teamName}
                    this.teamDetailsForEntity.push(newObj)
                });
                this.showTeamsPopup = true;
			}).catch((err:any)=>{
                console.log('erroe');
                this.showTeamsPopup = false;
                
            })
        }
	},
	async created(){
        this.dataLoading = true;
        if(Object.keys(this.$route.params).includes('regionId')){
            await this.getAllActiveUsers();
            await this.getAllRegions();
            await this.getAllEntities();
            this.dataLoading = false;
        }else{
            await this.getAllActiveUsers();
            await this.getAllRegions();
            await this.getAllEntities();
            this.dataLoading = false;
        }
		if(Object.keys(this.$route.params).includes('regionId')){
			this.breadCrumbUpdate();

			let obj
			obj = this.regionDataArray.find((newObj:any)=>{
				console.log('newObj',newObj,newObj._id,this.$route.params.regionId)
				return newObj._id == this.$route.params.regionId
			})
			// console.log('selected Array',obj);
            this.dummyRegionObject = {...this.regionObj}
            // console.log('dummyRegionObject1',this.dummyRegionObject)
			this.editRegion(obj);

			this.regionNamesExcludingPresentSystem = []
                this.regionDataArray.forEach((name: any)=>{
                    if(this.$route.params.regionId != name._id){
                        this.regionNamesExcludingPresentSystem.push(name.region)
                    }
                })
		 }
		 
	},
	async mounted() {
		emitter.off('dataTransfering')
		emitter.on('dataTransfering', (data: any) => {
			if(Object.keys(this.$route.params).includes('regionId')){
				this.$router.push({name:'editEntityForRegion', params:{regionId : data.region,entityId:data._id},query:{entity:true}})
			}
		});
        
        emitter.off('editEntity')
        emitter.on('editEntity', (data: any) => {
			this.$router.push({name:'editEntity', params:{entityId:data._id}})
		});

        emitter.off('showTeamsForEntity')
        emitter.on('showTeamsForEntity', async (data: any) => {
            this.selectedEntity = data;
            // console.log('Thisssssssss',this.selectedEntity)
            this.$router.push({name:'teamsForEntity', params:{entityName:data._id}})
            // let payload:any = { entities:[this.selectedEntity._id] };
            // await this.getTeamsForEntity(payload);
		});

		emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {                        
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
	}
});
</script>

